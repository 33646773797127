import { getEventTitle } from "@app/shared/events";
import { MembershipType, Sangha, SanghaType, Session, SessionType } from "@app/shared/types";
import { dateTimeFromString, getFormattedDateFromString } from "@app/shared/utils";
import { DateTime } from "luxon";

export const getSanghaGroupDescriptor = (sangha: Sangha): string => {
    switch (sangha.type) {
        case SanghaType.MentorshipGroup:
            return "Mentorship group";
        case SanghaType.InterestGroup:
            return "Mindful friends group";
        default:
            return "Sangha";
    }
};

export const getMentorshipGroupTitle = (sangha: Sangha): string => {
    let additionalInfo = "";

    if (sangha.eligibleMembershipTypes[0] === MembershipType.mmtcp) {
        additionalInfo = "For Meditation Teachers";
    } else if (sangha.audienceDisplay.toLowerCase() !== "all meditators") {
        switch (sangha.audienceDisplay.toLowerCase()) {
            case "women":
                additionalInfo = "For Women";
                break;
            case "people of color":
                additionalInfo = "For People of Color";
                break;
            case "60+ year olds":
                additionalInfo = "For 60+ Year Olds";
                break;
            case "lgbtqia+":
                additionalInfo = "For LGBTQIA+";
                break;
            case "experienced":
                additionalInfo = "For Experienced Meditators";
                break;
            case "psychotherapists":
                additionalInfo = "For Psychotherapists";
                break;
            case "mindful leadership":
                additionalInfo = "For Mindful Leaders";
                break;
            default:
                additionalInfo = `For ${sangha.audienceDisplay}`;
        }
    }

    return `Mentorship Group${additionalInfo && ` ${additionalInfo}`}`;
};

export const getSessionOrSanghaTitle = (session: Session, sangha?: Sangha): string => {
    if (session.type === SessionType.SANGHA_SESSION && !session.sanghaMembership && sangha) {
        return getMentorshipGroupTitle(sangha);
    } else {
        return getEventTitle({ event: session, sanghaName: session.sangha?.name });
    }
};

export const renderSessionDate = (session: Session, timezone: string) => {
    return dateTimeFromString(session.time, timezone).toLocaleString({
        weekday: "long",
        month: "long",
        day: "numeric",
    });
};

export const renderSessionTime = (session: Session, timezone: string) => {
    return `${getFormattedDateFromString(session.time, timezone)} - ${getFormattedDateFromString(
        session.endTime,
        timezone,
    )}`;
};

export const renderUserTimezone = (timezone: string) => {
    const dateTime = DateTime.now().setZone(timezone);
    const locale = navigator.language;
    const localDateTime = locale ? dateTime.setLocale(locale) : dateTime;
    return localDateTime.offsetNameShort || "";
};

export const renderNextSessionDate = (event: Session, timezone: string) => {
    const now = DateTime.now();
    const eventTime = dateTimeFromString(event.time, timezone);

    if (eventTime <= now) {
        return "The session has already started.";
    }
    const diff = eventTime.diff(now, ["weeks", "days", "hours", "minutes"]).toObject();

    let message = "";

    if ((diff.weeks ?? 0) >= 1) {
        const weeks = Math.floor(diff.weeks ?? 0);
        message = `Next session in ${weeks} week${weeks > 1 ? "s" : ""}.`;
    } else if ((diff.days ?? 0) >= 1) {
        const days = Math.floor(diff.days ?? 0);
        message = `Next session in ${days} day${days > 1 ? "s" : ""}.`;
    } else if ((diff.hours ?? 0) >= 1) {
        const hours = Math.floor(diff.hours ?? 0);
        message = `Next session in ${hours} hour${hours > 1 ? "s" : ""}.`;
    } else {
        const minutes = Math.floor(diff.minutes ?? 0);
        message = `Next session in ${minutes} minute${minutes > 1 ? "s" : ""}.`;
    }

    return message;
};
