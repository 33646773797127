import { useQuery } from "@apollo/client";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Container,
    IconButton,
    InputAdornment,
    TextField,
    Tooltip,
    Typography,
    useMediaQuery,
} from "@mui/material";
import {
    GRAPHQL_QUERY_GET_ALL_ACTIVE_SANGHA_TEACHER_IDS,
    GRAPHQL_QUERY_TEACHERS,
} from "app/queries";
import PageWrapper from "components/PageWrapper";
import { useQueryParams } from "use-query-params";
import {
    getTeacherSpecialtiesFilterOptionsFromTeachers,
    getTeacherSpecialtyFilterConfig,
} from "../filters/filterHelpers";

import { SanghaType, Teacher } from "@app/shared/types";
import makeStyles from "@mui/styles/makeStyles";
import { NAVBAR_HEIGHT } from "app/styles";
import { theme } from "app/theme";
import classNames from "classnames";
import { CircularProgressContainer } from "components/CircularProgressContainer";
import DocumentContext from "components/DocumentContext";
import { GenericErrorMessage } from "components/GenericErrorMessage";
import { MENU_SHADOW_HEIGHT } from "features/navigation/TopBarMenu";
import { useEffect, useState } from "react";
import { CustomArrayParam } from "../filters/customQueryParamDefinitions";
import { TeachersPageImageCard } from "./TeachersPageImageCard";
import { sortTeachersByLastName } from "./sortTeachers";

const useStyles = makeStyles((theme) => ({
    pageContainer: {
        display: "flex",
    },
    heroSection: {
        backgroundImage: `url("https://assets.cloudsangha.co/images/golden_fern-min.jpeg")`,
        color: theme.palette.neutralWhite,
        backgroundSize: "cover",
        position: "relative",
    },
    overlay: {
        backgroundColor: "rgba(0, 0, 0, 0.6)",
        position: "absolute",
        inset: 0,
    },
    heroText: {
        position: "relative",
        margin: theme.spacing(6, 0),
    },
    headerBar: {
        color: theme.palette.grey700,
        position: "sticky",
        top: NAVBAR_HEIGHT + MENU_SHADOW_HEIGHT, // sticky position when scrolling down, distance from top of the page
        padding: theme.spacing(2, 0),
        zIndex: theme.zIndex.appBar,
        backgroundColor: theme.palette.neutralWhite,
        boxShadow: theme.shadow.default,
        marginBottom: theme.spacing(1),
    },
    headerBarContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: theme.spacing(0, 4),
    },
    filterSidebar: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(2),
        width: 320,
        marginRight: theme.spacing(3),
        backgroundColor: theme.palette.grey100,
        padding: theme.spacing(3),
        borderRadius: theme.borderRadius.default,
        overflowY: "auto",
        position: "sticky",
        height: `calc(100vh - ${NAVBAR_HEIGHT}px - 105px)`, // 105px is the height of the header bar + the margins top and bottom of the filter bar
        top: NAVBAR_HEIGHT + MENU_SHADOW_HEIGHT + 81, // sticky position when scrolling down, distance from top of the page, 81px is the distance from the navbar to the filter bar
        [theme.breakpoints.down("md")]: {
            width: "100%",
            position: "fixed",
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            borderRadius: 0,
            zIndex: theme.zIndex.appBar,
            height: "100vh",
        },
    },
    filterSidebarHeader: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",

        [theme.breakpoints.down("md")]: {
            paddingBottom: theme.spacing(1),
        },
    },
    searchInputField: {
        "& .MuiOutlinedInput-root": {
            borderRadius: theme.borderRadius.large,
            marginBottom: 0,
        },
    },
    cardsContainer: {
        flexGrow: 1,
        display: "grid",
        gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))",
        gap: theme.spacing(3),
        alignContent: "space-between",
    },
    filterIcon: {
        marginRight: theme.spacing(1),
    },
    specialtiesAccordionFilter: {
        backgroundColor: theme.palette.grey100,
        borderRadius: 0,
        [theme.breakpoints.down("md")]: {
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
        },
    },
    filterTitle: {
        color: theme.palette.grey700,
        textTransform: "none",

        [theme.breakpoints.down("md")]: {
            fontSize: `${theme.typography.pxToRem(16)} !important`,
            lineHeight: `${theme.typography.pxToRem(16)} !important`,
        },
    },
    filterOptions: {
        [theme.breakpoints.down("md")]: {
            paddingLeft: theme.spacing(2),
        },
    },
}));

export const TeachersPage = () => {
    const classes = useStyles();
    const {
        data: teacherData,
        loading: teachersLoading,
        error: teachersError,
    } = useQuery(GRAPHQL_QUERY_TEACHERS);

    const {
        data: mentorsData,
        loading: mentorsLoading,
        error: mentorsError,
    } = useQuery(GRAPHQL_QUERY_GET_ALL_ACTIVE_SANGHA_TEACHER_IDS, {
        variables: { sanghaType: SanghaType.MentorshipGroup },
    });

    const teacherIdsWithMentorshipGroups = mentorsData?.getAllActiveSanghaTeacherIds || [];

    const [searchQuery, setSearchQuery] = useState("");
    const [queryParamsFilters, setQueryParamsFilters] = useQueryParams({
        specialties: CustomArrayParam,
    });
    const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false);
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    useEffect(() => {
        if (isFilterMenuOpen && isMobile) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
    }, [isFilterMenuOpen, isMobile]);

    if (teachersError || mentorsError) {
        return <GenericErrorMessage />;
    }
    const specialtiesFilterOptions = getTeacherSpecialtiesFilterOptionsFromTeachers(
        teacherData?.teachers || [],
    );

    let filteredTeachers: Teacher[] = (teacherData?.teachers || []).filter((teacher: Teacher) =>
        teacher.name.toLowerCase().includes(searchQuery.toLowerCase()),
    );
    let specialtyFilter = null;

    if (specialtiesFilterOptions) {
        specialtyFilter = getTeacherSpecialtyFilterConfig(
            queryParamsFilters,
            setQueryParamsFilters,
            specialtiesFilterOptions,
        );

        if (queryParamsFilters?.specialties && queryParamsFilters.specialties.length > 0) {
            filteredTeachers = filteredTeachers.filter((teacher: Teacher) =>
                teacher?.specialties?.some((specialty) =>
                    queryParamsFilters.specialties.includes(specialty),
                ),
            );
        }
    }

    const numFiltersApplied =
        (specialtyFilter?.getAppliedCount() || 0) + (searchQuery !== "" ? 1 : 0);
    const resetFilters = () => {
        setSearchQuery("");
        setQueryParamsFilters({
            specialties: undefined,
        });
    };

    const sortedTeachers = sortTeachersByLastName(filteredTeachers);

    return (
        <div>
            <DocumentContext title="Teachers" addBrandNameSuffix />
            <Box className={classes.heroSection}>
                <Box className={classes.overlay} />
                <PageWrapper smallPaddingTop>
                    <Box className={classes.heroText}>
                        <Typography variant="h1" sx={{ mb: 0 }}>
                            Connect with Expert Mindfulness Teachers
                        </Typography>
                        <Typography variant="body1" sx={{ mb: 0 }}>
                            Weekly Sessions to Learn, Ask, and Meditate Together
                        </Typography>
                    </Box>
                </PageWrapper>
            </Box>
            <Box className={classes.headerBar}>
                <Container maxWidth="lg" className={classes.headerBarContainer}>
                    <Button
                        variant="secondary"
                        size="small"
                        onClick={() => setIsFilterMenuOpen(!isFilterMenuOpen)}
                    >
                        <span
                            className={classNames("material-symbols-rounded", classes.filterIcon)}
                        >
                            {isFilterMenuOpen
                                ? "keyboard_double_arrow_left"
                                : "keyboard_double_arrow_right"}
                        </span>{" "}
                        Filters
                    </Button>
                    <Typography variant="caption" sx={{ mb: 0 }}>
                        {sortedTeachers.length} teachers
                    </Typography>
                </Container>
            </Box>
            <PageWrapper data-testid="memberTeachersPage" smallPaddingTop>
                {teachersLoading || mentorsLoading ? (
                    <CircularProgressContainer />
                ) : (
                    <Box className={classes.pageContainer}>
                        {isFilterMenuOpen && (
                            <Box className={classes.filterSidebar}>
                                {isMobile && (
                                    <Tooltip title="Close filters">
                                        <IconButton
                                            onClick={() => setIsFilterMenuOpen(false)}
                                            aria-label="Close filters"
                                            sx={{ p: 0, mb: 1, mr: "auto" }}
                                        >
                                            <span
                                                className={classNames("material-symbols-rounded")}
                                            >
                                                arrow_back
                                            </span>
                                        </IconButton>
                                    </Tooltip>
                                )}
                                <div className={classes.filterSidebarHeader}>
                                    <Typography variant="h5">
                                        Filters {numFiltersApplied > 0 && `(${numFiltersApplied})`}
                                    </Typography>
                                    {numFiltersApplied > 0 && (
                                        <Button variant="tertiary" onClick={() => resetFilters()}>
                                            Clear
                                        </Button>
                                    )}
                                </div>
                                <TextField
                                    size="small"
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                    className={classes.searchInputField}
                                    placeholder="Search"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <span className="material-symbols-rounded">
                                                    search
                                                </span>
                                            </InputAdornment>
                                        ),
                                    }}
                                />

                                {specialtyFilter && (
                                    <Accordion
                                        defaultExpanded={specialtyFilter.openByDefault}
                                        className={classes.specialtiesAccordionFilter}
                                    >
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                            <Typography
                                                variant="h6"
                                                className={classes.filterTitle}
                                            >
                                                {specialtyFilter.title}
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className={classes.filterOptions}>
                                            {specialtyFilter.render}
                                        </AccordionDetails>
                                    </Accordion>
                                )}
                            </Box>
                        )}
                        <div className={classes.cardsContainer}>
                            {sortedTeachers.map((teacher: Teacher) => (
                                <TeachersPageImageCard
                                    key={teacher.id}
                                    teacher={teacher}
                                    isMentor={teacherIdsWithMentorshipGroups.includes(teacher.id)}
                                />
                            ))}
                        </div>
                    </Box>
                )}
            </PageWrapper>
        </div>
    );
};
