import { Avatar, Box, Button, Link, Typography, alpha } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Teacher } from "@app/shared/types";
import { externalImageSrc } from "app/images";
import { useState } from "react";
import { theme } from "app/theme";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { routes } from "app/routes";

const useStyles = makeStyles((theme) => ({
    contentWrapper: {
        display: "flex",
        position: "relative",
        borderRadius: theme.borderRadius.default,
        padding: theme.spacing(4),
        background: theme.palette.grey100,
        [theme.breakpoints.down("md")]: {
            flexDirection: "column",
            textAlign: "center",
        },
    },
    avatar: {
        width: 260,
        height: 260,
        borderRadius: theme.borderRadius.default,
        borderTopRightRadius: 0,
        borderBottomLeftRadius: 0,
        marginRight: theme.spacing(10),
        cursor: "pointer",
        [theme.breakpoints.down("md")]: {
            margin: "auto",
            marginBottom: theme.spacing(2),
        },
        [theme.breakpoints.down("sm")]: {
            width: 154,
            height: 154,
        },
    },
    content: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
    },
    teacherName: {
        marginBottom: theme.spacing(1),
    },
    teacherBio: {
        whiteSpace: "pre-wrap",
        flexGrow: 1,
    },
}));

export const TeacherBioCard = (props: { teacher: Teacher }): JSX.Element => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const { teacher } = props;

    return (
        <div className={classes.contentWrapper}>
            <Avatar
                data-testid="teacherAvatar"
                className={classes.avatar}
                alt={teacher.name}
                src={teacher.picture ? externalImageSrc(teacher.picture, 260) : "EMPTY"}
                variant="circular"
                onClick={() => dispatch(push(routes.teacherPage(teacher.id)))}
            />

            <div className={classes.content}>
                <Typography variant="h4" className={classes.teacherName}>
                    {teacher.name}
                </Typography>

                <Box
                    sx={{
                        display: "flex",
                        gap: { xs: 0, md: 8 },
                        flexDirection: {
                            xs: "column",
                            md: "row",
                        },
                        mb: { xs: 1, md: 2 },
                    }}
                >
                    <Typography
                        variant="caption"
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                            justifyContent: {
                                xs: "center",
                                md: "flex-start",
                            },
                        }}
                    >
                        <span
                            className={"material-symbols-rounded"}
                            style={{
                                fontSize: theme.typography.pxToRem(20),
                                color: theme.palette.grey700,
                            }}
                        >
                            folded_hands
                        </span>
                        {teacher?.yearsMeditating} years meditating
                    </Typography>
                    <Typography
                        variant="caption"
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                            justifyContent: {
                                xs: "center",
                                md: "flex-start",
                            },
                        }}
                    >
                        <span
                            className={"material-symbols-rounded"}
                            style={{
                                fontSize: theme.typography.pxToRem(20),
                                color: theme.palette.grey700,
                            }}
                        >
                            play_arrow
                        </span>
                        {teacher?.yearsTeaching} years teaching
                    </Typography>
                </Box>

                <Typography variant="h6">About me</Typography>
                <Typography variant="body1" className={classes.teacherBio}>
                    {teacher.shortBio ?? teacher.background}
                </Typography>

                <Box
                    sx={{
                        justifyContent: {
                            xs: "center",
                            md: "flex-start",
                        },
                    }}
                >
                    <Button
                        variant="tertiary"
                        onClick={() => dispatch(push(routes.teacherPage(teacher.id)))}
                    >
                        Know more
                    </Button>
                </Box>
            </div>
        </div>
    );
};
