import { Sangha, SanghaType } from "@app/shared/types";
import { Button, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { analyticsTrack } from "app/analytics/track";
import { routes } from "app/routes";
import { NavLink } from "features/navigation/NavLink";
import { usePreferredGroup } from "hooks/usePreferredGroup";
import { useUserTimezone } from "hooks/useUserTimezone";
import { useLocation } from "react-router";
import InterestGroupCard from "./InterestGroupCard";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { FilterData } from "../filters/filterHelpers";

const useStyles = makeStyles((theme) => ({
    availableSpotsButton: {
        height: "67px",
        [theme.breakpoints.up("sm")]: {
            marginRight: theme.spacing(3),
        },
    },
    buttonContainer: {
        textAlign: "center",
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(2),
    },
    cardContainer: {
        marginTop: theme.spacing(1),
        display: "grid",
        gridTemplateColumns: "repeat(auto-fill, minmax(350px, 1fr))",
        gap: 22,
        [theme.breakpoints.up("md")]: {
            gridTemplateColumns: "repeat(2, 1fr)",
        },
        [theme.breakpoints.up("lg")]: {
            gridTemplateColumns: "repeat(3, 1fr)",
        },
    },
    surveyCard: {
        backgroundColor: theme.palette.accentEarthy,
        color: theme.palette.neutralWhite,
        borderRadius: theme.borderRadius.default,
        padding: theme.spacing(0, 3),
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        gap: theme.spacing(2),
        cursor: "pointer",
        minHeight: 280,

        "&:hover": {
            transform: "scale(1.025)",
            transition: "transform .3s",
            boxShadow: theme.shadow.spread,
        },
    },
    surveyContactText: {
        display: "flex",
        alignItems: "center",
    },
    secondaryButton: {
        backgroundColor: theme.palette.neutralWhite,
    },
}));

interface InterestGroupListProps {
    groupIdsToExclude?: string[];
    filters?: FilterData;
    resetFilters: VoidFunction;
    setGroupCount: (count: number) => void;
    groups: Sangha[];
}

export const InterestGroupList = (props: InterestGroupListProps) => {
    const classes = useStyles();
    const timezone = useUserTimezone();

    const { groupIdsToExclude, filters, resetFilters, setGroupCount, groups } = props;

    const preferredGroupId = usePreferredGroup();

    const location = useLocation();

    const filterQuery = new URLSearchParams(location.search).toString();

    const isGroupOpen = (group: Sangha) => group.hasAvailability === true;
    let allGroups = [...groups].filter(isGroupOpen); // temporarily filter out closed groups, switch back after session transition

    // List preferred group on top
    const preferredGroups = allGroups.filter((x) => x.id === preferredGroupId);
    if (preferredGroups.length > 0) {
        allGroups = [...preferredGroups, ...allGroups.filter((x) => x.id !== preferredGroupId)];
    }

    if (groupIdsToExclude) {
        allGroups = allGroups.filter((x) => !groupIdsToExclude.includes(x.id));
    }

    const allOpenGroups = allGroups.filter(isGroupOpen);

    // Sort groups by those with the most available spots on top
    allGroups = allGroups.sort((a, b) => a.availableSpots - b.availableSpots);

    const renderGroups = () => {
        if (allGroups.length < 1) {
            return (
                <>
                    <Typography variant="body1">
                        No available groups found.{" "}
                        {filters && (
                            <>
                                Please try adjusting your filters or{" "}
                                <NavLink to={routes.contactUs()}>get in touch with us</NavLink>.
                            </>
                        )}
                    </Typography>
                </>
            );
        } else {
            return allGroups.map((group) => (
                <InterestGroupCard key={group.id} interestGroup={group} filterQuery={filterQuery} />
            ));
        }
    };

    setGroupCount(allGroups.length);

    const renderButtons = () => {
        return (
            allGroups.length < allOpenGroups.length && (
                <div className={classes.buttonContainer}>
                    <Button
                        variant="primary"
                        onClick={() => {
                            analyticsTrack("Button Clicked", {
                                label: "Show me all groups",
                                type: SanghaType.InterestGroup,
                                location: "InterestGroupList",
                            });
                            resetFilters();
                        }}
                    >
                        Show me all groups
                    </Button>
                </div>
            )
        );
    };

    const renderSurveyCard = () => {
        return (
            <div
                className={classes.surveyCard}
                onClick={() => {
                    window.open(
                        `https://banyantogether.typeform.com/to/x7fuvpp4#timezone=${timezone}`,
                        "_blank",
                    );
                }}
            >
                <Typography variant="h3">Can’t find a day and time that works for you?</Typography>
                <Typography variant="body1" className={classes.surveyContactText}>
                    Join the waitlist <ChevronRightIcon />
                </Typography>
            </div>
        );
    };

    return (
        <>
            <div className={classes.cardContainer}>
                {renderGroups()}
                {allGroups.length > 0 && renderSurveyCard()}
            </div>
            {renderButtons()}
        </>
    );
};
