import { selectReferralCode, selectUtmParameters } from "features/navigation/navigationSlice";
import { isProduction, isRunningCypress } from "../config";
import store from "../store";
import QueryString from "query-string";
import { getUtmParameters } from "../utmParameters";
import analytics from "app/analytics/analytics";
import { selectLoginAs } from "features/auth/auth";
import { isUserInFreeTrialSession } from "features/signup/signupHelpers";

const CONSOLE_LOG_ANALYTICS_IN_DEV = false;

export const analyticsTrack = (eventName: string, eventPayload?: object): void => {
    // Do not log in automated tests
    if (isRunningCypress()) {
        return;
    }

    const referralCode =
        selectReferralCode(store.getState()) || QueryString.parse(window.location.search).ref;

    const loginAs = selectLoginAs(store.getState());

    // Add UTM parameters to analytics logs if present
    const utmParameters = selectUtmParameters(store.getState()) || getUtmParameters();

    const payload = {
        ...eventPayload,
        ...utmParameters,
        referralCode,

        adminLoggedInAsMember: Boolean(loginAs), // Tracks whether this is an admin viewing the UI for a member/teacher

        // Temporarily log this data on every analytics event to debug https://app.asana.com/0/1200532025380951/1206718601958467/f
        isUserInFreeTrialSession: isUserInFreeTrialSession(),
    };

    if (CONSOLE_LOG_ANALYTICS_IN_DEV && !isProduction()) {
        // eslint-disable-next-line no-console
        console.log(eventName, payload);
    }

    analytics.track(eventName, payload);
};
