import { Sangha } from "@app/shared/types";
import makeStyles from "@mui/styles/makeStyles";
import { usePreferredGroup } from "hooks/usePreferredGroup";
import { ImmersionGroupCard } from "../community/ImmersionGroupCard";

interface MentorshipGroupListProps {
    groups: Sangha[];
}

const useStyles = makeStyles((theme) => ({
    cardContainer: {
        display: "grid",
        gridTemplateColumns: "repeat(auto-fill, minmax(350px, 1fr))",
        gap: theme.spacing(3),
        [theme.breakpoints.up("md")]: {
            gridTemplateColumns: "repeat(2, 1fr)",
        },
        [theme.breakpoints.up("lg")]: {
            gridTemplateColumns: "repeat(3, 1fr)",
        },
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(4),
    },
}));

export const MentorshipGroupList = (props: MentorshipGroupListProps) => {
    const { groups } = props;
    const classes = useStyles();

    const preferredGroupId = usePreferredGroup();

    let availableGroups: Sangha[] | undefined = [...groups];

    availableGroups = availableGroups
        .filter((x) => x.nextSession)
        .sort((a, b) => b.openSpots - a.openSpots);

    // List preferred group on top
    const preferredGroups = availableGroups.filter((x) => x.id === preferredGroupId);
    if (preferredGroups.length > 0) {
        availableGroups = [
            ...preferredGroups,
            ...availableGroups.filter((x) => x.id !== preferredGroupId),
        ];
    }

    return (
        <div className={classes.cardContainer}>
            {availableGroups.map((group) => (
                <ImmersionGroupCard key={group.id} sangha={group} />
            ))}
        </div>
    );
};
