import { UserProfile } from "@app/shared/types";
import { Box, Button, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { analyticsTrack } from "app/analytics/track";
import {
    AgeRangeOptions,
    EthnicityOptions,
    GenderOptions,
    SexualOrientationOptions,
} from "app/constants";
import { useFormBuilder } from "features/formBuilder/useFormBuilder";
import _ from "lodash";
import { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectUserProfile, updateAccount } from "../../auth/auth";
import { validateProfile } from "./memberProfileHelpers";
import { DateTime } from "luxon";
import { LoggedInUserProfileContext } from "features/auth/AuthRoute";
import { IANATimezones } from "@app/shared/utils";
import ProfilePicture from "./ProfilePicture";
import { saveUserProfile } from "./memberProfileSlice";
import { CSSelectField } from "components/CSSelectField";
import { FixedCTAContainer } from "features/signup/FixedCTAContainer";
import PageWrapper from "components/PageWrapper";

const useStyles = makeStyles((theme) => ({
    contentContainer: {
        marginTop: theme.spacing(2),
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        position: "relative",
    },
    formField: {
        marginBottom: theme.spacing(2),
    },

    avatar: {
        width: 150,
        height: 150,
        cursor: "pointer",
        marginBottom: theme.spacing(4),

        "&:hover": {
            boxShadow: `0px 0px 10px ${theme.palette.grey800}`,
        },
    },

    avatarLoading: {
        width: "100%",
        height: 120,
        paddingTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
    },

    profilePicContainer: {
        [theme.breakpoints.up("sm")]: {
            alignItems: "center",
        },
    },

    profilePicImage: {
        paddingRight: theme.spacing(4),
    },

    timezoneDropdown: { marginBottom: "1rem" },
    profileSection: {
        display: "flex",
        marginBottom: theme.spacing(3),
        gap: theme.spacing(4),
        [theme.breakpoints.down("md")]: {
            flexDirection: "column",
            gap: theme.spacing(1),
        },
    },
    sectionTextContainer: {
        width: 400,
        flexShrink: 0,
    },
    sectionFieldsContainer: {
        backgroundColor: theme.palette.grey100,
        padding: theme.spacing(4),
        borderRadius: theme.borderRadius.default,
        width: "100%",
    },
    formRow: {
        display: "flex",
        gap: theme.spacing(2),
        [theme.breakpoints.down("md")]: {
            flexDirection: "column",
            gap: 0,
        },
    },
}));

type Fields = Pick<
    UserProfile,
    | "fullName"
    | "nickName"
    | "location"
    | "bio"
    | "pronouns"
    | "ageRange"
    | "ethnicity"
    | "sexualOrientation"
    | "gender"
    | "phone"
>;

const EditableFields = [
    "fullName",
    "nickName",
    "location",
    "bio",
    "pronouns",
    "ageRange",
    "ethnicity",
    "sexualOrientation",
    "gender",
] as const;

interface EditProfilePageProps {
    onContinue: () => void;
}

export const EditProfilePage = (props: EditProfilePageProps) => {
    const { onContinue } = props;
    const dispatch = useDispatch();
    const classes = useStyles();
    const user = useContext(LoggedInUserProfileContext);
    const [timezone, setTimezone] = useState(user.timeZone || DateTime.local().zoneName);

    const userProfile = useSelector(selectUserProfile) as UserProfile;

    const formDefaults = _.pick(userProfile, EditableFields);

    const { makeTextField, makeSelectField, onSubmit, submitted, validationResult, setData } =
        useFormBuilder<Fields>({
            defaults: formDefaults,
            validationFunction: (value) => ({
                ...validateProfile(value),
            }),
            onSave: async (data) => {
                const result = await dispatch(
                    saveUserProfile({
                        id: userProfile.id,
                        userProfile: data,
                    }),
                );

                const { payload } = result as any;

                if (payload) {
                    analyticsTrack("member.sangha.my_profile.saved");
                }
                dispatch(updateAccount({ timeZone: timezone }));

                onContinue();
            },
            textFieldProps: {
                className: classes.formField,
            },
        });

    const renderTimezoneDropdown = () => {
        return (
            <CSSelectField
                options={IANATimezones}
                value={timezone}
                onChange={(value) => {
                    setTimezone(value);
                }}
                label="Time zone"
                className={classes.timezoneDropdown}
                required
            />
        );
    };

    return (
        <PageWrapper smallPaddingTop>
            <div className={classes.contentContainer}>
                <Typography variant="h2">Set your Profile</Typography>
                <form className={classes.form}>
                    <Box className={classes.profileSection}>
                        <Box className={classes.sectionTextContainer}>
                            <Typography variant="h5">Putting yourself out there</Typography>
                            <Typography variant="body1">
                                Say cheese! Our community thrives when seeing each other's authentic
                                selves.
                            </Typography>
                        </Box>
                        <Box className={classes.sectionFieldsContainer}>
                            <div className={classes.profilePicContainer}>
                                <ProfilePicture />
                                {submitted && validationResult?.picture && (
                                    <Typography
                                        variant="body2"
                                        color="error"
                                        className="validatedFormFieldError"
                                    >
                                        {validationResult?.picture}
                                    </Typography>
                                )}
                            </div>
                        </Box>
                    </Box>

                    <Box className={classes.profileSection}>
                        <Box className={classes.sectionTextContainer}>
                            <Typography variant="h5">Some basic information</Typography>
                            <Typography variant="body1">
                                Everything but your time zone and full name will be public to other
                                members.
                            </Typography>
                        </Box>
                        <Box className={classes.sectionFieldsContainer}>
                            <Box className={classes.formRow}>
                                {makeTextField("fullName", "Your full name", {
                                    required: true,
                                })}
                                {makeTextField("nickName", "Your public name", {
                                    required: true,
                                })}
                            </Box>

                            <Box className={classes.formRow}>
                                {renderTimezoneDropdown()}
                                {makeTextField("pronouns", "Your pronouns")}
                            </Box>

                            <Box className={classes.formRow}>
                                {makeTextField("location", "Your location")}
                                {makeTextField("phone", "Your phone number")}
                            </Box>
                            {makeTextField("bio", "Your short intro", {
                                multiline: true,
                            })}
                        </Box>
                    </Box>

                    <Box className={classes.profileSection}>
                        <Box className={classes.sectionTextContainer}>
                            <Typography variant="h5">A little more info</Typography>
                            <Typography variant="body1">
                                This helps us understand the diversity of the community and share
                                relevant groups with you. This info is <strong>private</strong> from
                                other members.
                            </Typography>
                        </Box>

                        <Box className={classes.sectionFieldsContainer}>
                            <Box className={classes.formRow}>
                                {makeSelectField("ageRange", "Age group", AgeRangeOptions)}
                                {makeSelectField("gender", "Gender identity", GenderOptions)}
                            </Box>

                            <Box className={classes.formRow}>
                                {makeSelectField("ethnicity", "Ethnic identity", EthnicityOptions)}
                                {makeSelectField(
                                    "sexualOrientation",
                                    "Sexual orientation",
                                    SexualOrientationOptions,
                                )}
                            </Box>
                        </Box>
                    </Box>

                    <FixedCTAContainer>
                        <Button
                            variant="primary"
                            onClick={onSubmit}
                            type="submit"
                            role="button"
                            data-testid="saveProfileButton"
                            fullWidth
                        >
                            Save changes
                        </Button>
                    </FixedCTAContainer>
                </form>
            </div>
        </PageWrapper>
    );
};
