import CheckIcon from "@mui/icons-material/Check";
import CircleIcon from "@mui/icons-material/Circle";
import {
    alpha,
    AppBar,
    Step,
    StepConnector,
    stepConnectorClasses,
    StepIconProps,
    StepLabel,
    Stepper,
    styled,
    Typography,
    useMediaQuery,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { NAVBAR_HEIGHT, NAVBAR_HEIGHT_MOBILE } from "app/styles";
import { push } from "connected-react-router";
import { MENU_SHADOW_HEIGHT } from "features/navigation/TopBarMenu";
import { useDispatch } from "react-redux";
import { getCurrentCohort } from "./signupHelpers";
import { PartnerLogo } from "./PartnerLogo";
import { Logo } from "components/Logo";
import { theme } from "app/theme";
import { SetupSteps } from "features/setup/SetupFlow";

interface ProgressBarProps {
    currentStep: number;
    onStepClick?: (step: string) => void;
    stepData: { [key: string]: { step?: number; label: string; route?: string } };
    stepOrder: string[];
    nonClickableSteps?: string[];
}

const useStyles = makeStyles((theme) => ({
    checkIcon: {
        fontSize: theme.typography.pxToRem(24),
    },
    circleIcon: {
        fontSize: theme.typography.pxToRem(10),
    },
    stepper: {
        width: "80%",
        margin: "0 auto",
        marginTop: theme.spacing(2),
    },
    csStepIconRoot: {
        color: theme.palette.grey700,
    },
    csStepIconActive: {
        color: theme.palette.accentGold,
    },
    csStepIconCompleted: {
        color: theme.palette.accentGold,
    },
    customAppBar: {
        backgroundColor: theme.palette.primaryBanyan,
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        minHeight: NAVBAR_HEIGHT,
        overflow: "hidden",
        display: "flex",
        justifyContent: "center",

        "&:before": {
            content: "''",
            position: "absolute",
            zIndex: -1,
            left: 0,
            bottom: MENU_SHADOW_HEIGHT,
            top: 0,
            right: 0,
            boxShadow: `${MENU_SHADOW_HEIGHT}px 0 ${MENU_SHADOW_HEIGHT}px ${alpha(
                theme.palette.grey900,
                0.1,
            )}`,
            transition: "opacity 500ms ease",
            opacity: 1,
        },

        [theme.breakpoints.down("lg")]: {
            minHeight: NAVBAR_HEIGHT_MOBILE,
        },
    },
    iconContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: 24,
    },
    smallScreenStepsLabel: {
        color: theme.palette.accentGold,
        marginBottom: 0,
    },
    smallScreenStepsTitle: {
        marginBottom: 0,
        lineHeight: theme.typography.pxToRem(36),
        color: theme.palette.neutralWhite,

        [theme.breakpoints.down("sm")]: {
            lineHeight: theme.typography.pxToRem(22),
        },
    },
    smallScreenStepsContainer: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(8),
        [theme.breakpoints.down("md")]: {
            paddingLeft: theme.spacing(6),
        },
    },
    logoContainer: {
        display: "flex",
        alignItems: "center",
        position: "absolute",
        top: 0,
        left: 0,
        paddingLeft: theme.spacing(2),
        height: "100%",
        justifyContent: "space-between",
    },
    partnerLogo: {
        marginRight: theme.spacing(4),
    },
    altLabel: {
        marginTop: 0,
    },
    stepClickable: {
        cursor: "pointer",
        "&:hover": {
            textDecoration: "underline",
        },
    },
}));

function CsStepIcon(props: StepIconProps) {
    const classes = useStyles();
    const { active, completed } = props;

    return (
        <div
            className={
                completed
                    ? classes.csStepIconCompleted
                    : active
                      ? classes.csStepIconActive
                      : classes.csStepIconRoot
            }
        >
            <div className={classes.iconContainer}>
                {completed ? (
                    <CheckIcon className={classes.checkIcon} />
                ) : (
                    <CircleIcon className={classes.circleIcon} />
                )}
            </div>
        </div>
    );
}

const CsConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: theme.palette.accentGold,
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: theme.palette.accentGold,
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        borderColor: alpha(theme.palette.grey700, 0.8),
        borderTopWidth: 2,
        borderRadius: 1,
    },
}));

const CsStepLabel = styled(StepLabel)({
    [`& .MuiStepLabel-label.MuiStepLabel-alternativeLabel`]: {
        marginTop: 0,
        color: theme.palette.neutralWhite,
    },
    [`& .MuiStepLabel-labelContainer`]: {
        width: "fit-content",
    },
    [`& .MuiStepLabel-label.Mui-disabled`]: {
        color: theme.palette.grey700,
    },
    ["& .MuiStepLabel-label.Mui-active"]: {
        "& .MuiTypography-root": {
            fontWeight: 900,
        },
    },
});

export const ProgressBar = (props: ProgressBarProps) => {
    const { currentStep, stepData, stepOrder, nonClickableSteps = [], onStepClick } = props;
    const classes = useStyles();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));
    const dispatch = useDispatch();

    const cohort = getCurrentCohort();

    const MobileProgressBar = () => (
        <AppBar className={classes.customAppBar} position="sticky" elevation={0}>
            <div className={classes.logoContainer}>
                <Logo variant="brandmark" color="white" margin="none" height={50} width={50} />

                {cohort && <PartnerLogo cohort={cohort} />}
            </div>
            <div className={classes.smallScreenStepsContainer}>
                <Typography variant="body1" className={classes.smallScreenStepsLabel}>
                    <strong>
                        Step {currentStep + 1} of {stepOrder.length}
                    </strong>
                </Typography>
                <Typography variant="h2" className={classes.smallScreenStepsTitle}>
                    {stepData[stepOrder[currentStep]].label}
                </Typography>
            </div>
        </AppBar>
    );

    return (
        <>
            {isLargeScreen ? (
                <AppBar className={classes.customAppBar} position="sticky" elevation={0}>
                    <div className={classes.logoContainer}>
                        <Logo
                            variant="brandmark"
                            color="white"
                            margin="none"
                            height={50}
                            width={50}
                        />

                        {cohort && <PartnerLogo cohort={cohort} />}
                    </div>

                    <Stepper
                        alternativeLabel
                        activeStep={currentStep}
                        className={classes.stepper}
                        connector={<CsConnector />}
                    >
                        {stepOrder.map((stepKey) => {
                            const step = stepData[stepKey];
                            const isClickable =
                                currentStep > stepOrder.indexOf(stepKey) &&
                                !nonClickableSteps.includes(stepKey);

                            return (
                                <Step key={stepKey}>
                                    <CsStepLabel StepIconComponent={CsStepIcon}>
                                        <span
                                            className={isClickable ? classes.stepClickable : ""}
                                            onClick={(e) => {
                                                if (isClickable) {
                                                    if (onStepClick) {
                                                        onStepClick(stepKey);
                                                    } else if (step.route) {
                                                        dispatch(push(step.route));
                                                    }
                                                }
                                            }}
                                        >
                                            <Typography variant="body1">{step.label}</Typography>
                                        </span>
                                    </CsStepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>
                </AppBar>
            ) : (
                <MobileProgressBar />
            )}
        </>
    );
};
