import { IntentionInput, Recommendation } from "@app/shared/types";
import { Badge, Link, Tooltip, Typography, useMediaQuery } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { routes } from "app/routes";
import { theme } from "app/theme";
import classNames from "classnames";
import { LoggedInUserProfileContext } from "features/auth/RequireAuthentication";
import { LinkButton } from "features/navigation/LinkButton";
import { DateTime } from "luxon";
import { useContext, useEffect, useState } from "react";
import PageWrapper from "./PageWrapper";
import RecommendationCard from "./RecommendationCard";
import { SetIntentionButton } from "./SetIntentionButton";

const useStyles = makeStyles((theme) => ({
    fullWidthBackground: {
        backgroundColor: theme.palette.grey100,
    },
    header: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
        gap: theme.spacing(2),
        alignItems: "center",
        marginTop: theme.spacing(2),

        [theme.breakpoints.down("md")]: {
            flexDirection: "column",
            alignItems: "flex-start",
        },
    },
    intentionSummaryContainer: {
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        gap: theme.spacing(1),
        color: theme.palette.primaryLeaves,

        [theme.breakpoints.down("md")]: {
            flexDirection: "column",
            alignItems: "flex-start",
        },
    },
    intentionSummary: {
        color: theme.palette.grey900,
    },
    mountainFlagIcon: {
        marginRight: theme.spacing(1),
    },
    tooltipPopper: {
        zIndex: theme.zIndex.speedDial,
    },
    recommendedForYouTitle: {
        width: "fit-content",
        lineHeight: "normal",
        marginBottom: 0,
        marginTop: theme.spacing(3),

        [theme.breakpoints.down("sm")]: {
            marginTop: theme.spacing(2),
        },
    },
    recommendationBodyDescription: {
        lineHeight: "normal",
    },
    recommendationCardsContainer: {
        display: "grid",
        gridTemplateColumns: "repeat(auto-fill, minmax(350px, 1fr))",
        gap: theme.spacing(2),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    seeMoreRecommendationsLink: {
        color: theme.palette.grey700,
        fontStyle: "normal",
        textDecoration: "none",
        fontSize: theme.typography.pxToRem(16),
        marginLeft: theme.spacing(1),

        "&:hover": {
            textDecoration: "underline",
        },
    },
}));

export const CustomPathForYou = (props: {
    intention: IntentionInput;
    recommendations: Recommendation[];
}) => {
    const classes = useStyles();

    const [isIntentionOutdated, setIsIntentionOutdated] = useState(true);

    const isSmallViewport = useMediaQuery(theme.breakpoints.down("md"), {
        noSsr: true,
    });

    const user = useContext(LoggedInUserProfileContext);
    const userNickName = user.profile.nickName;

    const { intention, recommendations } = props;

    useEffect(() => {
        if (intention?.createdDate) {
            const intentionCreatedDate = DateTime.fromISO(String(intention.createdDate));
            const now = DateTime.now();
            const diffInMonths = now.diff(intentionCreatedDate, "months").months;

            setIsIntentionOutdated(diffInMonths >= 1);
        }
    }, []);

    const recommendationsTitle = () =>
        intention ? (
            <Typography
                variant="h4"
                className={classes.recommendedForYouTitle}
                textTransform="uppercase"
            >
                Recommended for you
            </Typography>
        ) : (
            <Tooltip
                placement="right"
                open={true}
                title="New!"
                classes={{ popper: classes.tooltipPopper }}
            >
                <Typography
                    variant="h4"
                    className={classes.recommendedForYouTitle}
                    textTransform="uppercase"
                >
                    Recommended for you
                </Typography>
            </Tooltip>
        );

    return (
        <div className={classes.fullWidthBackground}>
            <PageWrapper smallPaddingTop>
                <div className={classes.header}>
                    <Typography variant="h2" sx={{ marginBottom: 0 }}>
                        Welcome back, {userNickName}
                    </Typography>
                    {intention?.intentionSummary && (
                        <div className={classes.intentionSummaryContainer}>
                            <Typography variant="body1" sx={{ marginBottom: 0 }}>
                                <strong>
                                    My intention:{" "}
                                    <span className={classes.intentionSummary}>
                                        "{intention?.intentionSummary}"
                                    </span>
                                </strong>
                            </Typography>

                            {isIntentionOutdated ? (
                                <Tooltip
                                    placement={isSmallViewport ? "right" : "bottom"}
                                    open={true}
                                    title="Refresh intentions"
                                    classes={{ popper: classes.tooltipPopper }}
                                >
                                    <Badge
                                        color="error"
                                        variant="dot"
                                        sx={{
                                            "& .MuiBadge-badge": {
                                                height: "12px",
                                                width: "12px",
                                                borderRadius: "12px",
                                                right: "8px",
                                                top: "3px",
                                                border: `1px solid ${theme.palette.neutralWhite}`,
                                                zIndex: theme.zIndex.tooltip,
                                            },
                                        }}
                                    >
                                        <LinkButton
                                            data-testid="viewIntentionButton"
                                            to={routes.intentionDetailPage()}
                                            variant="primary"
                                            size="small"
                                            fullWidth={false}
                                            sx={{
                                                pl: theme.spacing(1) + "!important",
                                                pr: theme.spacing(1) + "!important",
                                                textTransform: "capitalize",
                                                width: "fit-content",
                                                minWidth: "auto",
                                            }}
                                        >
                                            <span
                                                className={classNames(
                                                    "material-symbols-rounded",
                                                    classes.mountainFlagIcon,
                                                )}
                                            >
                                                mountain_flag
                                            </span>
                                            View Intention
                                        </LinkButton>
                                    </Badge>
                                </Tooltip>
                            ) : (
                                <LinkButton
                                    to={routes.intentionDetailPage()}
                                    variant="secondary"
                                    size="small"
                                    fullWidth={false}
                                    sx={{
                                        pl: theme.spacing(1) + "!important",
                                        pr: theme.spacing(1) + "!important",
                                        textTransform: "capitalize",
                                        width: "fit-content",
                                        minWidth: "auto",
                                    }}
                                >
                                    <span
                                        className={classNames(
                                            "material-symbols-rounded",
                                            classes.mountainFlagIcon,
                                        )}
                                    >
                                        mountain_flag
                                    </span>
                                    View Intention
                                </LinkButton>
                            )}
                        </div>
                    )}
                </div>

                {recommendationsTitle()}
                {!intention?.fullIntention ? (
                    <Typography variant="body1" className={classes.recommendationBodyDescription}>
                        We'll recommend sessions below based on your intentions.
                    </Typography>
                ) : recommendations?.length > 0 ? (
                    <Typography variant="body1" className={classes.recommendationBodyDescription}>
                        Based on your intentions of this month
                    </Typography>
                ) : (
                    <Typography variant="body1" className={classes.recommendationBodyDescription}>
                        No recommendation found at the moment.
                    </Typography>
                )}

                {recommendations?.length > 0 ? (
                    isSmallViewport ? (
                        <>
                            <RecommendationCard
                                key={recommendations[0].title}
                                recommendation={recommendations[0]}
                            />
                            {recommendations?.length > 1 && (
                                <Link
                                    href={routes.intentionDetailPage()}
                                    className={classes.seeMoreRecommendationsLink}
                                >
                                    {recommendations.length - 1} more
                                </Link>
                            )}
                        </>
                    ) : (
                        <div className={classes.recommendationCardsContainer}>
                            {recommendations?.map((recommendation: Recommendation) => {
                                return (
                                    <RecommendationCard
                                        key={recommendation.title}
                                        recommendation={recommendation}
                                    />
                                );
                            })}
                        </div>
                    )
                ) : (
                    <SetIntentionButton userId={user.id} userEmail={user.email} />
                )}
            </PageWrapper>
        </div>
    );
};

export default CustomPathForYou;
