import { Container } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import classNames from "classnames";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
    root: {
        //textAlign: "center",
        padding: theme.spacing(4),
        marginTop: theme.spacing(4),

        [theme.breakpoints.down("md")]: {
            padding: theme.spacing(2),
            border: "none",
            boxShadow: "none",
            background: "inherit",
        },
    },
    smallPaddingTop: {
        marginTop: "0px !important",
        paddingTop: "8px !important",
    },
    removeMarginTop: {
        marginTop: "0px !important",
    },
    removeTopAndBottomMargins: {
        marginTop: "0px !important",
        marginBottom: "0px !important",
        paddingTop: "0px !important",
        paddingBottom: "0px !important",
    },
}));

export const PageWrapper = (props: {
    children: any;
    className?: any;
    maxWidth?: false | "xs" | "sm" | "md" | "lg" | "xl";
    smallPaddingTop?: boolean;
    removeTopAndBottomMargins?: boolean;
    removeMarginTop?: boolean;
}) => {
    const classes = useStyles();
    const {
        children,
        className,
        smallPaddingTop,
        removeTopAndBottomMargins,
        removeMarginTop,
        ...others
    } = props;
    const rootClass = className ? clsx(classes.root, className) : classes.root;

    return (
        <Container
            className={classNames(rootClass, {
                [classes.smallPaddingTop]: smallPaddingTop,
                [classes.removeTopAndBottomMargins]: removeTopAndBottomMargins,
                [classes.removeMarginTop]: removeMarginTop,
            })}
            maxWidth={props.maxWidth || "lg"}
            {...others}
        >
            {children}
        </Container>
    );
};

export default PageWrapper;
