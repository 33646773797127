import { useLazyQuery } from "@apollo/client";
import { INTAKE_SURVEY_RELEASE_DATE, TEACHER_SANGHA_ID } from "@app/shared/constants";
import {
    Sangha,
    SanghaMembership,
    SanghaSessionCycle,
    SanghaType,
    Session,
    SessionStarted,
} from "@app/shared/types";
import { AdvancedImage, responsive } from "@cloudinary/react";
import { Box, Button, Chip, Divider, IconButton, Tooltip, Typography } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import makeStyles from "@mui/styles/makeStyles";
import { cloudinary } from "app/cloudinary";
import { isRunningAutomatedTest } from "app/config";
import { GRAPHQL_QUERY_SANGHA_DETAILS, GRAPHQL_QUERY_SESSION_BY_ID } from "app/queries";
import { routes } from "app/routes";
import classNames from "classnames";
import { GenericErrorMessage } from "components/GenericErrorMessage";
import { GenericErrorPage } from "components/GenericErrorPage";
import PageWrapper from "components/PageWrapper";
import { push } from "connected-react-router";
import { selectIsAdmin, selectIsTeacher, selectLoggedInTeacherId } from "features/auth/auth";
import { LoggedInUserProfileContext } from "features/auth/RequireAuthentication";
import { NavLink } from "features/navigation/NavLink";
import LoadingPage from "features/pages/LoadingPage";
import { SanghaDescription } from "features/sangha/SanghaDescription";
import { useCurrentServerTime } from "hooks/useCurrentServerTime";
import { useFetchDataAndKeepUpdated } from "hooks/useFetchDataAndKeepUpdated";
import { useQueryParams } from "hooks/useQueryParams";
import { useUserTimezone } from "hooks/useUserTimezone";
import _ from "lodash";
import { DateTime } from "luxon";
import { useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useParams } from "react-router";
import { renderAddToCalendarMetadata } from "../community/AddToCalendarMenuItem";
import { LeaveGroupMenuItem } from "../community/LeaveGroupMenuItem";
import { renderNextSessionDate } from "../community/shared";
import { OpenLoungeButton } from "../lounge/OpenLoungeButton";
import { TeachersPageImageCard } from "../teachers/TeachersPageImageCard";
import { IntakeSurveyPage } from "./IntakeSurveyPage";
import { isSessionStarted } from "./isSessionStarted";
import { MemberCard } from "./MemberCard";
import { SanghaJoinButton } from "./SanghaJoinButton";
import { SanghaStartButton } from "./SanghaStartButton";

const useStyles = makeStyles((theme) => ({
    groupDetailContainer: {
        display: "flex",
        gap: theme.spacing(8),
        marginBottom: theme.spacing(4),
        [theme.breakpoints.down("md")]: {
            flexDirection: "column",
            gap: theme.spacing(2),
        },
    },
    titleContainer: {
        position: "relative",
        flexGrow: 1,
    },
    sanghaImage: {
        position: "absolute",
        zIndex: 1,
        width: "100%",
        borderRadius: theme.borderRadius.default,
        height: "-webkit-fill-available",
        objectFit: "cover",
    },
    textContainer: {
        position: "relative",
        zIndex: 2,
        color: theme.palette.neutralWhite,
        padding: theme.spacing(4),
    },
    editIcon: {
        fontSize: theme.typography.pxToRem(22),
    },
    buttonIcon: {
        fontSize: theme.typography.pxToRem(18),
        marginRight: theme.spacing(1),
    },
    functionsContainer: {
        width: 300,
        [theme.breakpoints.down("md")]: {
            margin: "0 auto",
        },
    },
    buttonContainer: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(1),
    },
    addToCalendarPopup: {
        zIndex: theme.zIndex.fab,
    },
    memberCardContainer: {
        display: "grid",
        gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))",
        gap: theme.spacing(3),
    },
}));

const dateTimeFromString = (timeString?: string) =>
    timeString ? DateTime.fromISO(timeString) : null;

export const SanghaGroupDetailPage = () => {
    const classes = useStyles();

    const { sanghaId } = useParams<{ sanghaId: string }>();
    const query = useQueryParams();

    const timezone = useUserTimezone();

    const dispatch = useDispatch();
    const loggedInUser = useContext(LoggedInUserProfileContext);
    const isTeacher = useSelector(selectIsTeacher);
    const loggedInTeacherId = useSelector(selectLoggedInTeacherId);
    const isAdmin = useSelector(selectIsAdmin);

    const {
        data: sanghaData,
        loading: sanghaLoading,
        error: sanghaError,
    } = useFetchDataAndKeepUpdated({
        query: GRAPHQL_QUERY_SANGHA_DETAILS,
        pollIntervalInMinutes: 30,
        options: { variables: { id: sanghaId } },
    });

    const [fetchSessionData, { data: sessionData, loading: sessionLoading, error: sessionError }] =
        useLazyQuery(GRAPHQL_QUERY_SESSION_BY_ID);

    const [nextSession, setNextSession] = useState<Session | null>(null);

    const sangha = sanghaData?.sangha as Sangha;

    const nextSessionStartTime = useMemo(
        () => dateTimeFromString(nextSession?.time),
        [nextSession?.time],
    );
    const nextSessionEndTime = useMemo(
        () => dateTimeFromString(nextSession?.endTime),
        [nextSession?.endTime],
    );

    const currentServerTime = useCurrentServerTime();

    useEffect(() => {
        const fetchData = async () => {
            if (sangha?.nextSession && !nextSession) {
                await fetchSessionData({
                    variables: { sessionId: sangha.nextSession.id },
                });
            }
        };
        void fetchData();
    }, [sangha]);

    useEffect(() => {
        if (sessionData?.sessionById && !nextSession) {
            setNextSession(sessionData.sessionById);
        }
    }, [sessionData]);

    useEffect(() => {
        if (nextSession && (window as any).addeventatc) {
            (window as any).addeventatc.refresh();
        }
    }, [nextSession]);

    if (sanghaLoading || sessionLoading) {
        return <LoadingPage />;
    }

    if (sanghaError || sessionError) {
        return <GenericErrorMessage />;
    }

    if (!sangha) {
        return (
            <GenericErrorPage alternateInstruction="We could not find the group you are looking for. Please" />
        );
    }

    const sessionStartedStatus = isSessionStarted({
        sessionStart: nextSessionStartTime,
        sessionEnd: nextSessionEndTime,
        currentServerTime,
    });

    const sessionInProgress = sessionStartedStatus === SessionStarted.IN_PROGRESS;
    const sessionStartingSoon = sessionStartedStatus === SessionStarted.ABOUT_TO_START;
    const sessionCancelled = nextSession?.cancelled;

    const enableSessionButtons =
        isAdmin || (!sessionCancelled && (sessionInProgress || sessionStartingSoon));

    const sanghaImage = cloudinary.image(sangha.topicData?.imageId).format("auto");

    const sanghaMemberships = sangha.memberships as SanghaMembership[];
    const mySanghaMembership = sanghaMemberships.find(
        (membership) => membership.user.profile.id === loggedInUser.id,
    );

    const userIsTeacherOfEvent =
        isTeacher &&
        (_.includes(
            nextSession?.teachers?.map((x) => x.id),
            loggedInTeacherId,
        ) ||
            sangha.teacher.id === loggedInTeacherId);

    const isSubstituteTeacher = userIsTeacherOfEvent && sangha.teacher.id !== loggedInTeacherId;

    if (!mySanghaMembership && !isTeacher && !isAdmin) {
        return <Redirect to={routes.memberHomePage()} />;
    }

    const renderWarnings = () => {
        if (!nextSession || !nextSessionStartTime) {
            return (
                <MuiAlert variant="filled" severity="warning">
                    No next session has been scheduled for this group. Please{" "}
                    <NavLink to={routes.contactUs()}>contact us</NavLink> if you think this is a
                    mistake.
                </MuiAlert>
            );
        }

        // If the session is starting in the next 2 hours, but there's no zoom info available, show an error (we return zoom info for sessions starting within the next 3 hours)
        if (!nextSession.zoomInfo && nextSessionStartTime < DateTime.now().plus({ hours: 2 })) {
            return (
                <MuiAlert variant="filled" severity="error">
                    We're having trouble communicating with Zoom. Please refresh the page or{" "}
                    <NavLink to={routes.contactUs()}>contact us</NavLink> if you continue
                    experiencing this issue.
                </MuiAlert>
            );
        }
    };

    const isMentorshipGroup = sangha.type === SanghaType.MentorshipGroup;
    const hasJoinedGroupAfterIntakeSurveyRelease =
        mySanghaMembership &&
        DateTime.fromISO(mySanghaMembership?.startDate) > INTAKE_SURVEY_RELEASE_DATE;
    const hasCompletedIntakeSurvey = !!mySanghaMembership?.hasCompletedIntakeSurvey;
    const isRunningInAutomatedTest = isRunningAutomatedTest() || query.skip_survey === "true"; // skip_survey is a temporary solution because isRunningPlaywright() is not working
    const showIntakeSurvey =
        isMentorshipGroup &&
        !userIsTeacherOfEvent &&
        hasJoinedGroupAfterIntakeSurveyRelease &&
        !hasCompletedIntakeSurvey &&
        !isRunningInAutomatedTest;

    const teacherEmail = sangha.teacher.userProfile.email;

    if (showIntakeSurvey) {
        return (
            <IntakeSurveyPage
                sanghaId={sangha.id}
                userId={loggedInUser.id}
                email={loggedInUser.email}
                sanghaMembershipId={mySanghaMembership.id}
                teacherEmail={teacherEmail || ""}
            />
        );
    }

    return (
        <PageWrapper>
            <Box className={classes.groupDetailContainer}>
                <Box className={classes.titleContainer}>
                    <Box
                        sx={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            zIndex: 2,
                            backgroundColor: "rgba(0, 0, 0, 0.5)",
                            width: "100%",
                            height: "100%",
                            borderRadius: 1,
                        }}
                    />
                    {sangha.type === SanghaType.InterestGroup ? (
                        <AdvancedImage
                            className={classes.sanghaImage}
                            cldImg={sanghaImage}
                            plugins={[responsive({ steps: 200 })]}
                        />
                    ) : (
                        <img
                            className={classes.sanghaImage}
                            src={"https://assets.cloudsangha.co/images/plants.jpeg"}
                            alt="sangha"
                        />
                    )}
                    <Box className={classes.textContainer}>
                        <Typography variant="h1" sx={{ mb: 0 }}>
                            {sangha.name}
                        </Typography>
                        <Typography variant="body1">
                            {sangha.type === SanghaType.InterestGroup
                                ? sangha.presentationData?.subtitle
                                : `Meet with the same group and teacher each week on your shared
                                journey.`}
                        </Typography>
                        <SanghaDescription
                            sangha={sangha}
                            timeZone={loggedInUser.timeZone || DateTime.local().zoneName}
                            invertColor
                        />
                        {!!nextSession &&
                            (sessionCancelled ? (
                                <MuiAlert variant="filled" severity="warning">
                                    Next session is canceled
                                </MuiAlert>
                            ) : (
                                <Typography variant="caption">
                                    <strong>{renderNextSessionDate(nextSession, timezone)}</strong>
                                </Typography>
                            ))}
                        {renderWarnings()}
                    </Box>
                </Box>
                <Box className={classes.functionsContainer}>
                    <Typography variant="h4">My group details</Typography>
                    {sangha.type === SanghaType.InterestGroup && !!mySanghaMembership && (
                        <>
                            {mySanghaMembership?.isDropIn ? (
                                <Chip
                                    label="Drop-in Participant"
                                    variant="pink"
                                    sx={{ mb: 0.5, mt: 0.5 }}
                                />
                            ) : (
                                <Chip
                                    label="Dedicated Participant"
                                    variant="green"
                                    sx={{ mb: 0.5, mt: 0.5 }}
                                />
                            )}
                            <Tooltip title="Change group commitment">
                                <IconButton
                                    size="small"
                                    sx={{ ml: 1 }}
                                    onClick={() =>
                                        dispatch(
                                            push({
                                                pathname: routes.memberConfirmInterestGroup(
                                                    sangha.id,
                                                ),
                                                state: { isSwitchingGroupParticipation: true },
                                            }),
                                        )
                                    }
                                >
                                    <span
                                        className={classNames(
                                            "material-symbols-rounded",
                                            classes.editIcon,
                                        )}
                                    >
                                        edit
                                    </span>
                                </IconButton>
                            </Tooltip>
                        </>
                    )}
                    <Divider sx={{ mt: 1, mb: 1 }} />
                    <Box className={classes.buttonContainer}>
                        {(isTeacher || isAdmin) && enableSessionButtons && (
                            <SanghaStartButton sangha={sangha} />
                        )}
                        {(!isTeacher || sangha.id === TEACHER_SANGHA_ID) &&
                            enableSessionButtons && <SanghaJoinButton sangha={sangha} />}
                        <OpenLoungeButton
                            buttonVariant={enableSessionButtons ? "secondary" : "primary"}
                            sanghaId={sangha.id}
                        />
                        {!!nextSession && (
                            <div
                                className={classNames("addeventatc", classes.addToCalendarPopup)}
                                data-styling="none"
                            >
                                <Button variant="secondary" size="small" fullWidth>
                                    <span
                                        className={classNames(
                                            "material-symbols-rounded",
                                            classes.buttonIcon,
                                        )}
                                    >
                                        calendar_add_on
                                    </span>
                                    Add to calendar
                                    {renderAddToCalendarMetadata(
                                        nextSession,
                                        timezone,
                                        sangha?.cycle === SanghaSessionCycle.weekly &&
                                            !isSubstituteTeacher,
                                    )}
                                </Button>
                            </div>
                        )}
                        {!!mySanghaMembership && sangha.type === SanghaType.InterestGroup && (
                            <>
                                <Divider sx={{ mt: 1, mb: 1 }} />
                                <LeaveGroupMenuItem
                                    sangha={sangha}
                                    sanghaMembershipId={mySanghaMembership.id}
                                    renderAsButton
                                />
                            </>
                        )}
                    </Box>
                </Box>
            </Box>
            <Box>
                <Typography variant="h3">Members ({sanghaMemberships.length})</Typography>
                <Box className={classes.memberCardContainer}>
                    <TeachersPageImageCard teacher={sangha.teacher} showTeacherBadge />

                    {sanghaMemberships?.map((membership: SanghaMembership) => (
                        <MemberCard
                            key={membership.user.id}
                            userProfile={membership.user.profile}
                        />
                    ))}
                </Box>
            </Box>
        </PageWrapper>
    );
};
