import { useMutation } from "@apollo/client";
import {
    MembershipBillingChoice,
    MembershipBillingChoiceInput,
    MembershipBillingPlan,
} from "@app/shared/types";
import { analyticsTrack } from "app/analytics/track";
import { isRunningCypress } from "app/config";
import { GRAPHQL_MUTATION_CREATE_MEMBERSHIP_CHECKOUT_SESSION } from "app/queries";
import { routes } from "app/routes";
import { getUtmParametersFromLocalStorage, UtmPrefix } from "app/utmParameters";
import { showSnackbarAlertOnRedirect } from "components/AlertSnackBar";
import { push } from "connected-react-router";
import { COMMITTED_INTEREST_GROUP_FIELD } from "features/member/interestGroups/constants";
import { selectReferralCode } from "features/navigation/navigationSlice";
import LoadingPage from "features/pages/LoadingPage";
import { useAffiliatePartnerTracking } from "hooks/useAffiliatePartnerTracking";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CheckoutPage } from "./CheckoutPage";
import { getCurrentCohort, isUserInFreeTrialSession } from "./signupHelpers";
import { SignupProgressBar } from "./SignupProgressBar";
import { GENERIC_ERROR_MESSAGE, GenericErrorMessage } from "components/GenericErrorMessage";
import PageWrapper from "components/PageWrapper";

interface StripeClientSecretProviderProps {
    chosenPlan: MembershipBillingPlan;
    trialDays?: number;
    selectedGroupId?: string;
    couponCode?: string;
}

export const StripeClientSecretProvider = (props: StripeClientSecretProviderProps) => {
    const dispatch = useDispatch();

    const { chosenPlan, trialDays, selectedGroupId, couponCode } = props;

    const referralCode = useSelector(selectReferralCode);
    const affiliatePartner = useAffiliatePartnerTracking();

    const [error, setError] = useState<string | null>(null);

    const cohort = getCurrentCohort();

    const [generateCheckoutSession] = useMutation(
        GRAPHQL_MUTATION_CREATE_MEMBERSHIP_CHECKOUT_SESSION,
    );

    const [clientSecret, setClientSecret] = useState("");

    const billingChoice: MembershipBillingChoice = { plan: chosenPlan, donationAmount: 0 };

    const onCompleteBilling = async () => {
        const billingChoiceInput: MembershipBillingChoiceInput = {
            planName: billingChoice.plan.planName,
            donationAmount: billingChoice.donationAmount ?? 0,
        };

        const referredByPartnerId = affiliatePartner?.partnerId;

        try {
            const result = await generateCheckoutSession({
                variables: {
                    billingChoice: billingChoiceInput,
                    referralCode,
                    utmParameters: getUtmParametersFromLocalStorage(),
                    affiliateUtmParameters: getUtmParametersFromLocalStorage(UtmPrefix.AFFILIATE),
                    scholarshipApplicationId: undefined,
                    referredByPartnerId,
                    isFreeTrial: billingChoice.plan.membershipType === "explorer" ? true : false,
                    isDropIn: window.sessionStorage.getItem("isDropIn") === "true",
                    cohort: cohort,
                    trialDays,
                    selectedGroupId,
                    couponCode: couponCode,
                },
            });
            const { sessionId, clientSecret } = result.data.createMembershipCheckoutSession;
            setClientSecret(clientSecret);
            if (isRunningCypress()) {
                (window as any).stripeSessionId = sessionId;
            }
        } catch (e) {
            if (e instanceof Error) {
                if (e.message?.includes("User already has active/pending memberships")) {
                    analyticsTrack("checkout.payment.active-membership");
                    showSnackbarAlertOnRedirect("You already have an active membership");
                    dispatch(push(routes.memberZone()));
                } else if (e.message?.includes("User already has an active subscription")) {
                    analyticsTrack("checkout.payment.active-subscription");
                    setError(
                        "You already have an active subscription. If you still cannot access our member zone, please",
                    );
                } else {
                    analyticsTrack("checkout.payment.unknown-error", { message: e.message });
                    setError(GENERIC_ERROR_MESSAGE);
                }
            } else {
                analyticsTrack("checkout.payment.unknown-error", { message: e });
                setError(GENERIC_ERROR_MESSAGE);
            }
        }
    };

    useEffect(() => {
        onCompleteBilling();
    }, []);

    if (error) {
        return (
            <>
                <SignupProgressBar currentStep={1} />
                <PageWrapper>
                    <GenericErrorMessage alternateInstruction={error} />
                </PageWrapper>
            </>
        );
    }

    if (!clientSecret) {
        return (
            <>
                <SignupProgressBar currentStep={1} />
                <LoadingPage />
            </>
        );
    } else {
        return <CheckoutPage clientSecret={clientSecret} billingChoice={billingChoice} />;
    }
};
