import styled from "@emotion/styled";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Drawer,
    IconButton,
    Tooltip,
    Typography,
    useMediaQuery,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { theme } from "app/theme";
import classNames from "classnames";
import { FilterConfig } from "../filters/FilterSidebar";
import { DRAWER_WIDTH, SESSIONS_PAGE_HEIGHT, SESSIONS_PAGE_HEIGHT_MOBILE } from "./SessionsPage";

const useStyles = makeStyles((theme) => ({
    paper: {
        backgroundColor: theme.palette.grey100,

        position: "relative",
        height: SESSIONS_PAGE_HEIGHT,
        [theme.breakpoints.down("md")]: {
            height: SESSIONS_PAGE_HEIGHT_MOBILE,
        },
    },
    paperMobile: {
        width: "100%",
        backgroundColor: theme.palette.neutralWhite,
    },
    filterDrawerTopBar: {
        borderBottom: "rgb(218,220,224) 1px solid",
        backgroundColor: theme.palette.grey100,
        position: "sticky",
        top: 0,
        zIndex: theme.zIndex.appBar,
        paddingTop: theme.spacing(1),
        paddingBottom: 9,
    },
    filterDrawerHeader: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        height: 41,

        [theme.breakpoints.down("md")]: {
            paddingBottom: theme.spacing(1),
        },
    },
    accordion: {
        backgroundColor: theme.palette.grey100,
        borderRadius: 0,
        [theme.breakpoints.down("md")]: {
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
        },
    },
    filterTitle: {
        color: theme.palette.grey700,
        textTransform: "none",

        [theme.breakpoints.down("md")]: {
            fontSize: `${theme.typography.pxToRem(16)} !important`,
            lineHeight: `${theme.typography.pxToRem(16)} !important`,
        },
    },
    filterOptions: {
        [theme.breakpoints.down("md")]: {
            paddingLeft: theme.spacing(2),
        },
    },
}));

interface FilterSidebarProps {
    filters: FilterConfig[];
    numFiltersApplied: number;
    resetFilters: VoidFunction;
    isFilterMenuOpen: boolean;
    setIsFilterMenuOpen: (isOpen: boolean) => void;
}

export const FilterDrawer = (props: FilterSidebarProps) => {
    const { filters, numFiltersApplied, resetFilters, isFilterMenuOpen, setIsFilterMenuOpen } =
        props;
    const classes = useStyles();

    const isMobileView = useMediaQuery(theme.breakpoints.down("md"));

    const AddingBorderDiv = styled.div`
        &::after {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            top: -1px;
            height: 1px;
            background-color: ${theme.palette.grey200};
            opacity: 1;
        }
    `;

    return (
        <Drawer
            anchor="left"
            open={isFilterMenuOpen}
            onClose={() => setIsFilterMenuOpen(false)}
            classes={{ paper: isMobileView ? classes.paperMobile : classes.paper }}
            variant="persistent"
            sx={{
                flexShrink: 0,
                width: isMobileView && isFilterMenuOpen ? "100%" : DRAWER_WIDTH,
            }}
        >
            {isMobileView && (
                <div style={{ boxShadow: theme.shadow.default, marginBottom: theme.spacing(1) }}>
                    <Tooltip title="Close filters">
                        <IconButton
                            onClick={() => setIsFilterMenuOpen(false)}
                            aria-label="Close mobile menu"
                        >
                            <span className={classNames("material-symbols-rounded")}>
                                arrow_back
                            </span>
                        </IconButton>
                    </Tooltip>
                    <div className={classes.filterDrawerHeader}>
                        <Typography variant="h3" sx={{ m: 0, color: theme.palette.grey700 }}>
                            Filters ({numFiltersApplied})
                        </Typography>
                        {numFiltersApplied > 0 && (
                            <Button variant="tertiary" onClick={() => resetFilters()}>
                                Clear
                            </Button>
                        )}
                    </div>
                </div>
            )}

            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                {!isMobileView && (
                    <div className={classes.filterDrawerTopBar}>
                        <div className={classes.filterDrawerHeader}>
                            <Typography variant="h5" sx={{ m: 0, color: theme.palette.grey700 }}>
                                Filters ({numFiltersApplied})
                            </Typography>
                            {numFiltersApplied > 0 && (
                                <Button variant="tertiary" onClick={() => resetFilters()}>
                                    Clear
                                </Button>
                            )}
                        </div>
                    </div>
                )}
                <div style={{ overflow: "auto" }}>
                    {filters.map((filter, index) =>
                        filter.showWithoutNesting ? (
                            <AddingBorderDiv key={index} className={classes.filterOptions}>
                                {filter.render}
                            </AddingBorderDiv>
                        ) : (
                            <Accordion
                                key={index}
                                className={classes.accordion}
                                defaultExpanded={filter.openByDefault}
                                sx={{ "&:last-child": { borderRadius: 0 } }}
                            >
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography variant="h6" className={classes.filterTitle}>
                                        {filter.title}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails className={classes.filterOptions}>
                                    {filter.render}
                                </AccordionDetails>
                            </Accordion>
                        ),
                    )}
                </div>
            </div>
        </Drawer>
    );
};
