import FullCalendar from "@fullcalendar/react";
import {
    Button,
    Divider,
    Drawer,
    IconButton,
    ToggleButton,
    ToggleButtonGroup,
    Tooltip,
    Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { theme } from "app/theme";
import classNames from "classnames";
import { CalendarViewType } from "./CalendarView";
import { SessionView } from "./SessionsPage";

const useStyles = makeStyles((theme) => ({
    paper: {
        width: "100%",
    },
    settingsDrawerContent: {
        width: "100%",
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
    },
    toggleButtonGroup: {
        width: "100%",
    },
    toggleButton: {
        justifyContent: "left",
        border: "none",

        borderBottomLeftRadius: `${theme.borderRadius.large}px !important`,
        borderBottomRightRadius: `${theme.borderRadius.large}px !important`,
        borderTopLeftRadius: `${theme.borderRadius.large}px !important`,
        borderTopRightRadius: `${theme.borderRadius.large}px !important`,

        paddingLeft: theme.spacing(4),
        textTransform: "none",

        "&.Mui-selected": {
            backgroundColor: theme.palette.neutralWhite,
            border: `2px solid ${theme.palette.grey200} !important`,
            color: theme.palette.accentGold,
        },

        "&:hover": {
            backgroundColor: theme.palette.grey100,
            cursor: "pointer",
        },
    },
}));

interface SettingsSidebarProps {
    settingsDrawerOpen: boolean;
    setSettingsDrawerOpen: (isOpen: boolean) => void;
    sessionView: SessionView;
    handleSessionViewChange: (
        event: React.MouseEvent<HTMLElement>,
        newSessionView: SessionView | null,
    ) => void;
    calendarRef: React.RefObject<FullCalendar>;
    calendarView: CalendarViewType;
    setCalendarView: (view: CalendarViewType) => void;
}

export const SettingsDrawer = (props: SettingsSidebarProps) => {
    const {
        settingsDrawerOpen,
        setSettingsDrawerOpen,
        sessionView,
        handleSessionViewChange,
        calendarRef,
        calendarView,
        setCalendarView,
    } = props;
    const classes = useStyles();

    const toggleSettingsDrawer = () => {
        setSettingsDrawerOpen(!settingsDrawerOpen);
    };

    const getCalendarApi = () => {
        return calendarRef.current?.getApi();
    };
    const getCurrentView = () => {
        const calendarApi = getCalendarApi();
        return calendarApi?.view.type as
            | CalendarViewType.MONTH
            | CalendarViewType.THREEDAYS
            | CalendarViewType.DAY;
    };
    const goToToday = () => {
        const calendarApi = getCalendarApi();
        calendarApi?.today();
    };

    const handleCalendarViewChange = (
        event: React.MouseEvent<HTMLElement>,
        nextView: CalendarViewType.MONTH | CalendarViewType.THREEDAYS | CalendarViewType.DAY,
    ) => {
        if (nextView !== null) {
            changeCalendarView(nextView);
        }
        toggleSettingsDrawer();
    };

    const changeCalendarView = (
        viewType: CalendarViewType.MONTH | CalendarViewType.THREEDAYS | CalendarViewType.DAY,
    ) => {
        const calendarApi = getCalendarApi();
        calendarApi?.changeView(viewType);
        setCalendarView(viewType);
    };

    return (
        <Drawer
            anchor="left"
            open={settingsDrawerOpen}
            onClose={() => setSettingsDrawerOpen(false)}
            classes={{ paper: classes.paper }}
            variant="persistent"
            sx={{
                flexShrink: 0,
            }}
        >
            <div style={{ boxShadow: theme.shadow.default, marginBottom: theme.spacing(3) }}>
                <Tooltip title="Close settings">
                    <IconButton onClick={toggleSettingsDrawer} aria-label="Close mobile menu">
                        <span className={classNames("material-symbols-rounded")}>arrow_back</span>
                    </IconButton>
                </Tooltip>
                <Typography variant="h3" sx={{ ml: 2, pb: 1 }}>
                    View settings
                </Typography>
            </div>

            <div className={classes.settingsDrawerContent}>
                <ToggleButtonGroup
                    orientation="vertical"
                    value={sessionView}
                    exclusive
                    onChange={handleSessionViewChange}
                    aria-label="session view"
                    className={classes.toggleButtonGroup}
                >
                    <ToggleButton
                        value="calendar"
                        aria-label="calendar"
                        data-testid="calendarToggleButton"
                        className={classes.toggleButton}
                    >
                        {" "}
                        <span
                            className={classNames("material-symbols-rounded")}
                            style={{ marginRight: 8 }}
                        >
                            calendar_today
                        </span>
                        Calendar
                    </ToggleButton>
                    <ToggleButton
                        value="list"
                        aria-label="list"
                        data-testid="listToggleButtonMobile"
                        className={classes.toggleButton}
                    >
                        {" "}
                        <span
                            className={classNames("material-symbols-rounded")}
                            style={{ marginRight: 8 }}
                        >
                            sort
                        </span>
                        List
                    </ToggleButton>
                </ToggleButtonGroup>

                {sessionView === "calendar" && (
                    <>
                        <Divider />

                        <ToggleButtonGroup
                            orientation="vertical"
                            value={calendarView || getCurrentView()}
                            exclusive
                            onChange={handleCalendarViewChange}
                            className={classes.toggleButtonGroup}
                        >
                            <ToggleButton
                                value={CalendarViewType.DAY}
                                aria-label="day"
                                className={classes.toggleButton}
                            >
                                {" "}
                                <span
                                    className={classNames("material-symbols-rounded")}
                                    style={{ marginRight: 8 }}
                                >
                                    view_day
                                </span>
                                Day
                            </ToggleButton>
                            <ToggleButton
                                value={CalendarViewType.THREEDAYS}
                                aria-label="week"
                                className={classes.toggleButton}
                            >
                                {" "}
                                <span
                                    className={classNames("material-symbols-rounded")}
                                    style={{ marginRight: 8 }}
                                >
                                    calendar_view_week
                                </span>
                                3 Days
                            </ToggleButton>
                            <ToggleButton
                                value={CalendarViewType.MONTH}
                                aria-label="month"
                                className={classes.toggleButton}
                            >
                                <span
                                    className={classNames("material-symbols-rounded")}
                                    style={{ marginRight: 8 }}
                                >
                                    calendar_view_month
                                </span>
                                Month
                            </ToggleButton>
                        </ToggleButtonGroup>

                        <Divider />

                        <div style={{ paddingRight: 16, paddingLeft: 16, marginTop: 16 }}>
                            <Button
                                variant="secondary"
                                fullWidth
                                onClick={() => {
                                    goToToday();
                                    toggleSettingsDrawer();
                                }}
                            >
                                Today
                            </Button>
                        </div>
                    </>
                )}
            </div>
        </Drawer>
    );
};
