import { Button } from "@mui/material";
import { analyticsTrack } from "app/analytics/track";
import Guidelines from "components/Guidelines";
import PageWrapper from "components/PageWrapper";
import { selectUserProfile } from "features/auth/auth";
import { acceptCommunityCommitments } from "features/member/profile/memberProfileSlice";
import LoadingPage from "features/pages/LoadingPage";
import { useLogEventOnLoad } from "hooks/useLogEventOnLoad";
import { useDispatch, useSelector } from "react-redux";
import { FixedCTAContainer } from "features/signup/FixedCTAContainer";

interface SetupGuidelinesPageProps {
    onContinue: () => void;
}

export const SetupGuidelinesPage = (props: SetupGuidelinesPageProps) => {
    const { onContinue } = props;

    const userProfile = useSelector(selectUserProfile);
    const dispatch = useDispatch();

    useLogEventOnLoad("onboarding.commitments.load");

    if (!userProfile) {
        return <LoadingPage />;
    }

    const onAccept = async () => {
        analyticsTrack("onboarding.commitments.submit");
        dispatch(
            acceptCommunityCommitments({
                id: userProfile.id,
            }),
        );
        onContinue();
    };

    return (
        <PageWrapper>
            <Guidelines />
            <FixedCTAContainer>
                <Button data-testid="continueButton" variant="primary" onClick={onAccept}>
                    I agree
                </Button>
            </FixedCTAContainer>
        </PageWrapper>
    );
};
