import { useMutation, useQuery } from "@apollo/client";
import { ProductFeature, Sangha, SanghaType } from "@app/shared/types";
import useLocalStorage from "@rehooks/local-storage";
import { analyticsTrack } from "app/analytics/track";
import {
    GRAPHQL_MUTATION_ADD_INTEREST_GROUP,
    GRAPHQL_MUTATION_UPDATE_GROUP_COMMITMENT,
    GRAPHQL_QUERY_AVAILABLE_INTEREST_GROUPS,
    GRAPHQL_QUERY_EVENT_RSVP_USERS,
    GRAPHQL_QUERY_INTEREST_GROUP,
    GRAPHQL_QUERY_MY_SANGHAS,
    GRAPHQL_QUERY_MY_SCHEDULE,
} from "app/queries";
import { routes } from "app/routes";
import { showSnackbarAlertOnRedirect } from "components/AlertSnackBar";
import { GenericErrorPage } from "components/GenericErrorPage";
import { push } from "connected-react-router";
import { selectUser } from "features/auth/auth";
import LoadingPage from "features/pages/LoadingPage";
import { useUserTimezone } from "hooks/useUserTimezone";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router";
import { COMMITTED_INTEREST_GROUP_FIELD } from "./constants";
import { InterestGroupChooseCommitmentPage } from "./InterestGroupChooseCommitmentPage";
import { getCurrentCohort, isUserInFreeTrialSession } from "features/signup/signupHelpers";

interface LocationState {
    isSwitchingGroupParticipation?: boolean;
}

export const InterestGroupConfirmationPage = () => {
    const user = useSelector(selectUser);
    const dispatch = useDispatch();
    const timezone = useUserTimezone();

    const { groupId } = useParams<{ groupId: string }>();

    const [isSubmitting, setIsSubmitting] = useState(false);

    const location = useLocation<LocationState>();
    const isSwitchingGroupParticipation = location.state?.isSwitchingGroupParticipation;

    const isFreeTrialSignupFlow = isUserInFreeTrialSession();

    const [scrollToNewMindfulGroup, setScrollToNewMindfulGroup] = useLocalStorage<
        string | undefined
    >("SCROLL_TO_NEW_INTEREST_GROUP");
    const {
        data: interestGroupData,
        loading: interestGroupLoading,
        error: interestGroupError,
    } = useQuery(GRAPHQL_QUERY_INTEREST_GROUP, {
        variables: { groupId },
    });

    const interestGroup = interestGroupData?.interestGroup as Sangha | undefined;

    const [updateGroupCommitmentMutation] = useMutation(GRAPHQL_MUTATION_UPDATE_GROUP_COMMITMENT);

    const [addInterestGroupMutation, { loading: isSaving, error: addInterestGroupError }] =
        useMutation(GRAPHQL_MUTATION_ADD_INTEREST_GROUP, {
            refetchQueries: [
                {
                    query: GRAPHQL_QUERY_MY_SANGHAS,
                },
                {
                    query: GRAPHQL_QUERY_AVAILABLE_INTEREST_GROUPS,
                },
                {
                    query: GRAPHQL_QUERY_MY_SCHEDULE,
                },
                {
                    query: GRAPHQL_QUERY_EVENT_RSVP_USERS,
                    variables: {
                        id: interestGroup?.nextSession?.id,
                    },
                },
            ],
        });

    if (interestGroupLoading || !interestGroup) {
        return <LoadingPage />;
    }

    if (interestGroupError) {
        return <GenericErrorPage />;
    }

    const updateGroupCommitmentForExistingMembership = async (isDropIn: boolean) => {
        await updateGroupCommitmentMutation({
            variables: {
                sanghaId: interestGroup.id,
                isDropIn: isDropIn,
            },
        });

        analyticsTrack("Change group participation", {
            id: interestGroup.id,
            type: SanghaType.InterestGroup,
            location: "InterestGroupConfirmationPage",
            isDropIn,
        });

        setScrollToNewMindfulGroup(interestGroup.id);
        showSnackbarAlertOnRedirect("Your group participation has been updated");
        dispatch(push(routes.memberZone()));
    };

    const createNewSanghaMembership = async (isDropIn: boolean) => {
        analyticsTrack("Commit to Group", {
            interestGroupId: interestGroup?.id,
            interestGroupName: interestGroup?.name,
            interestGroupTopic: interestGroup?.topicData?.name,
            isDropIn,
            isFreeTrial: isFreeTrialSignupFlow,
            cohort: getCurrentCohort(),
        });
        if (user?.features.includes(ProductFeature.CORE_MEMBERSHIP)) {
            setIsSubmitting(true);
            await addInterestGroupMutation({
                variables: {
                    sanghaId: interestGroup.id,
                    isDropIn: isDropIn,
                },
            }).catch(() => {
                setScrollToNewMindfulGroup(interestGroup.id);
                dispatch(push(routes.memberZone()));
            });
            setScrollToNewMindfulGroup(interestGroup.id);

            showSnackbarAlertOnRedirect("Your mindful group has been added");
            dispatch(push(routes.memberZone()));
        } else {
            window.sessionStorage.setItem(COMMITTED_INTEREST_GROUP_FIELD, interestGroup.id);

            dispatch(push(routes.signup()));
        }
    };

    const handleInterestGroupCommitment = async (isDropIn = false) => {
        if (isSwitchingGroupParticipation) {
            updateGroupCommitmentForExistingMembership(isDropIn);
        } else {
            createNewSanghaMembership(isDropIn);
        }
    };

    return (
        <>
            <InterestGroupChooseCommitmentPage
                interestGroup={interestGroup}
                timezone={timezone}
                onCommitmentConfirmation={handleInterestGroupCommitment}
                isSubmitting={isSubmitting}
                isSwitchingGroupParticipation={isSwitchingGroupParticipation}
            />
        </>
    );
};
