import { Course, UserCourseStatus } from "@app/shared/types";
import { IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { routes } from "app/routes";
import classNames from "classnames";
import { useState } from "react";
import { useHistory } from "react-router";
import { CourseOptOutDialog } from "./CourseOptOutDialog";
import { OnboardingItem } from "./OnboardingItem";

interface CourseOnboardingItemProps {
    completed: boolean;
    title: string;
    link: string;
    course: Course;
    onOptOut: () => void;
}

const useStyles = makeStyles((theme) => ({
    link: {
        textTransform: "uppercase",
        textDecoration: "none",
        display: "flex",
        alignItems: "center",
        gap: 4,
    },
    linkText: {
        textDecoration: "underline",
    },
    linkIcon: {
        textDecoration: "none",
    },
    menuButton: {
        color: theme.palette.grey800,
        display: "flex",
        alignItems: "center",
        textDecoration: "none",
        padding: 0,
    },
    menu: {
        borderRadius: theme.borderRadius.default,
        backgroundColor: theme.palette.background.default,
        overflow: "visible",
    },
    menuItem: {
        marginBottom: 0,
    },
}));

export const MindfulRelatingOnboardingItem = (props: CourseOnboardingItemProps) => {
    const { completed, title, link, course, onOptOut } = props;
    const classes = useStyles();
    const history = useHistory();

    const courseCompleted = course?.myCourseMembership?.status === UserCourseStatus.COMPLETED;
    const optedOut = course?.myCourseMembership?.status === UserCourseStatus.OPTED_OUT;
    const interactedWithCourse = !!course?.myCourseMembership;
    const completedOrOptedOut = courseCompleted || optedOut;

    const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);

    const [optOutDialogOpen, setOptOutDialogOpen] = useState(false);

    const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setMenuAnchor(event.currentTarget);
    };

    const handleMenuClose = () => {
        setMenuAnchor(null);
    };

    const renderMenuButton = ({
        isMenuOpen,
        setIsMenuOpen,
    }: {
        isMenuOpen: boolean;
        setIsMenuOpen: (isOpen: boolean) => void;
    }) => {
        return (
            <>
                <Tooltip title="See options">
                    <IconButton
                        onClick={(event) => {
                            handleMenuOpen(event);
                            setIsMenuOpen(true);
                        }}
                        className={classes.menuButton}
                        data-testid="courseMenuButton"
                    >
                        <span className={classNames("material-symbols-rounded")}>more_vert</span>
                    </IconButton>
                </Tooltip>
                <Menu
                    anchorEl={menuAnchor}
                    keepMounted={false}
                    open={isMenuOpen}
                    onClose={() => {
                        handleMenuClose();
                        setIsMenuOpen(false);
                    }}
                    classes={{ paper: classes.menu }}
                >
                    {!completedOrOptedOut && (
                        <MenuItem
                            onClick={() => setOptOutDialogOpen(true)}
                            className={classes.menuItem}
                        >
                            Skip this step
                        </MenuItem>
                    )}
                    {interactedWithCourse && (
                        <MenuItem
                            onClick={() =>
                                history.push(routes.courseEmbeddedLandingPage(course?.id))
                            }
                            className={classes.menuItem}
                        >
                            Know more
                        </MenuItem>
                    )}
                </Menu>
                <CourseOptOutDialog
                    dialogOpen={optOutDialogOpen}
                    onClose={() => {
                        setOptOutDialogOpen(false);
                        handleMenuClose();
                        setIsMenuOpen(false);
                    }}
                    course={course}
                    onOptOut={onOptOut}
                />
            </>
        );
    };

    return (
        <OnboardingItem
            completed={completed}
            title={title}
            link={link}
            customActionButton={renderMenuButton}
        />
    );
};
