import { UserProfile } from "@app/shared/types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GRAPHQL_MUTATION_ACCEPT_COMMITMENTS, GRAPHQL_MUTATION_SAVE_PROFILE } from "app/queries";
import { setUserProfile } from "features/auth/auth";
import firebase from "firebase/app";
import { apolloClient } from "../../../app/apollo";
import { createErrorLoggingAsyncThunk } from "../../../app/fetchers";

export const saveUserProfile = createErrorLoggingAsyncThunk(
    "userProfiles/saveUserProfile",
    async (
        params: {
            id: string;
            userProfile: {
                bio?: string | null;
                name?: string | null;
                fullName?: string;
                nickName?: string;
                location?: string | null;
                picture?: string | null;
                pronouns?: string | null;
                gender?: string | null;
                phone?: string | null;
                ageRange?: string | null;
                ethnicity?: string | null;
                sexualOrientation?: string | null;
            };
        },
        thunkAPI,
    ) => {
        const result = await apolloClient.mutate({
            mutation: GRAPHQL_MUTATION_SAVE_PROFILE,
            variables: params,
        });

        thunkAPI.dispatch(setUserProfile(result.data.saveUserProfile));
        return result.data.saveUserProfile;
    },
);

export const acceptCommunityCommitments = createErrorLoggingAsyncThunk(
    "userProfiles/acceptCommunityCommitments",
    async (
        params: {
            id: string;
        },
        thunkAPI,
    ) => {
        const result = await apolloClient.mutate({
            mutation: GRAPHQL_MUTATION_ACCEPT_COMMITMENTS,
            variables: params,
        });

        thunkAPI.dispatch(setUserProfile(result.data.acceptCommunityCommitments));
        return result.data.acceptCommunityCommitments;
    },
);

export const saveProfilePicture = createErrorLoggingAsyncThunk(
    "userProfiles/saveProfilePicture",
    async (params: { userProfile: UserProfile; file?: any; data?: string }, thunkAPI) => {
        const { userProfile, file, data } = params;
        const ref = firebase.storage().ref(`avatars/${userProfile.id}.jpg`);

        const result = await (file
            ? ref.put(file)
            : data
              ? ref.putString(data, "data_url")
              : undefined);

        if (result && result.state === firebase.storage.TaskState.SUCCESS) {
            const url = (await result.ref.getDownloadURL()) as string;
            const newFields = {
                picture: url,
            };

            await thunkAPI.dispatch(
                saveUserProfile({ id: userProfile.id, userProfile: newFields }),
            );
            return url;
        }
        return "";
    },
);

const memberProfileSlice = createSlice({
    name: "memberProfile",
    initialState: {
        profile: null as UserProfile | null,
    },
    reducers: {},
    extraReducers: {
        [saveUserProfile.fulfilled.toString()]: (
            state: any,
            action: PayloadAction<UserProfile>,
        ) => {
            const newProfile = action.payload;
            state.profile = newProfile;
        },
    },
});

export const memberProfileReducer = memberProfileSlice.reducer;
export default memberProfileReducer;
