import {
    DialogActions,
    DialogContent,
    DialogTitle,
    Dialog as MaterialDialog,
    DialogProps as MaterialDialogProps,
    Tooltip,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import makeStyles from "@mui/styles/makeStyles";
import classNames from "classnames";
import _ from "lodash";
import * as React from "react";
import { useModalNavigation } from "../hooks/useModalNavigation";

const useStyles = makeStyles((theme) => ({
    dialog: {},
    dialogContainer: {
        padding: theme.spacing(6, 6, 6, 6),
        marginTop: theme.spacing(2),
        background: "none",

        "& > :first-child": {
            marginTop: 0,
        },
    },

    closeIcon: {
        position: "absolute",
        top: theme.spacing(2),
        right: theme.spacing(2),
        padding: 0,
        zIndex: 1,
        fontSize: theme.typography.pxToRem(35),
    },

    closeIconLarge: {
        fontSize: theme.typography.pxToRem(60),
    },
}));

export type CSDialogProps = {
    children: React.ReactNode | React.ReactNode[];
    closeButtonSize?: "large" | "small";
    onClose?: (ev: React.MouseEvent<any>) => void;
    open: boolean;
    classes?: Partial<ReturnType<typeof useStyles>>;
    transitionDuration?: number;
    dialogClasses?: MaterialDialogProps["classes"];
    title?: React.ReactNode;
    actions?: React.ReactNode;
    alignTitle?: "inherit" | "right" | "left" | "center" | "justify";
} & MaterialDialogProps;

export const CSDialog = (props: CSDialogProps) => {
    const classes = useStyles(props);
    const navigation = useModalNavigation();

    const {
        children,
        closeButtonSize = "large",
        onClose = () => navigation.goBack(),
        open,
        dialogClasses,
        title,
        actions,
        alignTitle,
        ...others
    } = props;

    return (
        <MaterialDialog
            className={classes.dialog}
            onClose={onClose}
            open={open}
            fullWidth
            maxWidth="md"
            data-testid="modal"
            transitionDuration={props.transitionDuration}
            classes={dialogClasses}
            {..._.omit(others, "classes")}
        >
            <Tooltip title="Close">
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    data-testid="closeModalButton"
                    className={classes.closeIcon}
                >
                    <span
                        className={classNames("material-symbols-rounded", {
                            [classes.closeIconLarge]: closeButtonSize === "large",
                        })}
                    >
                        close
                    </span>
                </IconButton>
            </Tooltip>

            {title && <DialogTitle align={alignTitle}>{title}</DialogTitle>}

            <DialogContent className={classes.dialogContainer}>{children}</DialogContent>

            {actions && <DialogActions>{actions}</DialogActions>}
        </MaterialDialog>
    );
};
