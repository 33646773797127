import { Sangha } from "@app/shared/types";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Tooltip } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import makeStyles from "@mui/styles/makeStyles";
import { routes } from "app/routes";
import DocumentContext from "components/DocumentContext";
import PageWrapper from "components/PageWrapper";
import { push } from "connected-react-router";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { InterestGroupChooseCommitmentOptionsInfo } from "./InterestGroupChooseCommitmentOptionsInfo";
import { InterestGroupCommitmentDetailInfo } from "./InterestGroupCommitmentDetailInfo";
import { CommitmentOptions } from "./constants";

interface InterestGroupChooseCommitmentPageProps {
    interestGroup: Sangha;
    timezone: string;
    onCommitmentConfirmation: (isDropIn: boolean) => void;
    isSubmitting: boolean;
    isSwitchingGroupParticipation?: boolean;
}

const useStyles = makeStyles((theme) => ({
    reducedMarginWrapper: {
        marginTop: theme.spacing(0),
    },
}));

export const InterestGroupChooseCommitmentPage = (
    props: InterestGroupChooseCommitmentPageProps,
) => {
    const {
        interestGroup,
        timezone,
        onCommitmentConfirmation,
        isSubmitting,
        isSwitchingGroupParticipation,
    } = props;

    const classes = useStyles();

    const [commitmentOption, setCommitmentOption] = useState<CommitmentOptions | undefined>(
        undefined,
    );

    const dispatch = useDispatch();
    const { groupId } = useParams<{ groupId: string }>();

    const handleBackButtonClick = () => {
        if (commitmentOption !== undefined) {
            setCommitmentOption(undefined);
            window.sessionStorage.removeItem("isDropIn");
        } else {
            if (isSwitchingGroupParticipation) {
                dispatch(push(routes.memberHomePage()));
            }
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [commitmentOption]);

    function getContent() {
        if (commitmentOption === CommitmentOptions.Commited) {
            return (
                <InterestGroupCommitmentDetailInfo
                    isDropIn={false}
                    onCommitmentConfirmation={onCommitmentConfirmation}
                    isSubmitting={isSubmitting}
                    interestGroup={interestGroup}
                    timezone={timezone}
                />
            );
        } else if (commitmentOption === CommitmentOptions.DropIn) {
            return (
                <InterestGroupCommitmentDetailInfo
                    isDropIn={true}
                    onCommitmentConfirmation={onCommitmentConfirmation}
                    isSubmitting={isSubmitting}
                    interestGroup={interestGroup}
                    timezone={timezone}
                />
            );
        } else {
            return (
                <InterestGroupChooseCommitmentOptionsInfo
                    setCommitmentOption={setCommitmentOption}
                    isSubmitting={isSubmitting}
                />
            );
        }
    }

    return (
        <>
            <DocumentContext title="Choose Your Commitment" addBrandNameSuffix />
            <PageWrapper
                className={classes.reducedMarginWrapper}
                data-testid="InterestGroupChooseCommitmentPage"
            >
                <Tooltip title="Go back">
                    <IconButton onClick={handleBackButtonClick} aria-label="go back">
                        <ArrowBackIcon style={{ fontSize: "30px" }} />
                    </IconButton>
                </Tooltip>

                {getContent()}
            </PageWrapper>
        </>
    );
};
