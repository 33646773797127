import {
    Checkbox,
    CheckboxProps,
    FormControl,
    FormControlLabel,
    FormHelperText,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import _ from "lodash";
import * as React from "react";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "block",
        marginBottom: theme.spacing(2),
    },
}));

export interface CSCheckboxFieldProps
    extends Omit<CheckboxProps, "variant" | "onChange" | "error" | "helperText"> {
    label: React.ReactNode;
    onChange?: (checked: boolean) => void;
    showError?: boolean;
    error?: string | null;
}

export const CSCheckboxField = (props: CSCheckboxFieldProps) => {
    const classes = useStyles();

    const hasError = props.showError && Boolean(props.error);

    return (
        <FormControl className={classes.root} error={hasError}>
            <FormControlLabel
                // style={{ alignItems: "start" }}
                label={props.label}
                color="primary"
                control={
                    <Checkbox
                        {..._.omit(props, "error", "showError", "label", "onChange")}
                        onChange={(ev, checked) => props.onChange && props.onChange(checked)}
                    />
                }
            />
            {hasError && <FormHelperText>{props.error}</FormHelperText>}
        </FormControl>
    );
};
