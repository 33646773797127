import { useQuery } from "@apollo/client";
import { Recommendation } from "@app/shared/types";

import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { GRAPHQL_QUERY_ALL_RECOMMENDATIONS } from "app/queries";
import { theme } from "app/theme";
import { GenericErrorPage } from "components/GenericErrorPage";
import PageWrapper from "components/PageWrapper";
import { RecommendationCard } from "components/RecommendationCard";
import LoadingPage from "./LoadingPage";

const useStyles = makeStyles((theme) => ({
    recommendationCardsContainer: {
        display: "grid",
        gridTemplateColumns: "repeat(auto-fill, minmax(350px, 1fr))",
        gap: theme.spacing(2),
        [theme.breakpoints.up("md")]: {
            gridTemplateColumns: "repeat(2, 1fr)",
        },
        [theme.breakpoints.up("lg")]: {
            gridTemplateColumns: "repeat(3, 1fr)",
        },
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(4),
    },
}));

export const RecommendationsListPage = () => {
    const classes = useStyles();

    const {
        data: recommendationsData,
        loading: recommendationsLoading,
        error: recommendationsError,
    } = useQuery(GRAPHQL_QUERY_ALL_RECOMMENDATIONS, { fetchPolicy: "network-only" });

    if (!recommendationsData || recommendationsLoading) {
        return <LoadingPage />;
    }

    if (recommendationsError) {
        return <GenericErrorPage />;
    }
    const allRecommendations = recommendationsData.allRecommendations;

    return (
        <PageWrapper smallPaddingTop>
            <div>
                <Typography
                    variant="h6"
                    sx={{
                        marginTop: theme.spacing(4),
                    }}
                >
                    All recommendations list:
                </Typography>
                <Typography variant="body1" sx={{ color: theme.palette.grey700 }}>
                    This view was created to visualize the recommendation cards design
                </Typography>

                <div className={classes.recommendationCardsContainer}>
                    {allRecommendations.map((recommendation: Recommendation) => {
                        return (
                            <RecommendationCard
                                key={recommendation.title}
                                recommendation={recommendation}
                            />
                        );
                    })}
                </div>
            </div>
        </PageWrapper>
    );
};
