import { UserProfile } from "@app/shared/types";
import { Avatar, Box, Button, CircularProgress, Link, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useState } from "react";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectUserProfile } from "../../auth/auth";
import { saveProfilePicture } from "./memberProfileSlice";
import Webcam from "react-webcam";
import { NavLink } from "features/navigation/NavLink";
import { theme } from "app/theme";

const useStyles = makeStyles((theme) => ({
    avatar: {
        width: 300,
        height: 200,
        borderRadius: theme.borderRadius.default,
        marginRight: theme.spacing(4),

        [theme.breakpoints.down("sm")]: {
            width: 150,
            height: 100,
        },
    },

    avatarLoading: {
        width: "100%",
        height: 120,
        paddingTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
    },
    profilePicImage: {
        paddingRight: theme.spacing(4),
    },

    temporaryPicture: {
        backgroundColor: theme.palette.primaryLeaves,
        height: 200,
        width: 300,
        borderRadius: theme.borderRadius.default,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        color: "white",
        textAlign: "center",
        marginRight: theme.spacing(4),
        [theme.breakpoints.down("sm")]: {
            width: 150,
            height: 100,
        },
    },
    webCamButton: {},
    photoContainer: {
        display: "flex",
    },
    photoButtonContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        gap: theme.spacing(2),
    },
}));

export const ProfilePicture = () => {
    const dispatch = useDispatch();
    const classes = useStyles();

    const userProfile = useSelector(selectUserProfile) as UserProfile;

    const [savingPicture, setSavingPicture] = useState(false);

    const [webCamEnabled, setWebCamEnabled] = useState(false);
    const [tookWebcamPhoto, setTookWebcamPhoto] = useState(false);

    const [webCamImage, setWebCamImage] = useState("");
    const [existingPicture, setExistingPicture] = useState(userProfile?.picture);

    const videoConstraints = {
        width: 1920,
        height: 1080,
        facingMode: "user",
    };

    const WebcamCapture = () => (
        <Webcam
            audio={false}
            height={200}
            minScreenshotHeight={200}
            minScreenshotWidth={300}
            screenshotFormat="image/jpeg"
            width={300}
            videoConstraints={videoConstraints}
            mirrored={true}
            forceScreenshotSourceSize
        >
            {({ getScreenshot }) => (
                <Button
                    variant="primary"
                    data-testid="startButton"
                    onClick={() => {
                        const imageSrc = getScreenshot();
                        if (imageSrc) {
                            setWebCamImage(imageSrc);
                            setWebCamEnabled(false);
                            setTookWebcamPhoto(true);
                            uploadPicture(undefined, imageSrc);
                        }
                    }}
                    style={{
                        display: "block",
                    }}
                    className={classes.webCamButton}
                    size="small"
                >
                    <span
                        className="material-symbols-rounded"
                        style={{
                            marginRight: theme.spacing(1),
                            fontSize: theme.typography.pxToRem(22),
                        }}
                    >
                        add_a_photo
                    </span>
                    Take photo
                </Button>
            )}
        </Webcam>
    );

    const onPictureUpload = async (ev: React.ChangeEvent<any>) => {
        setWebCamEnabled(false);
        if (ev.target.validity.valid) {
            uploadPicture(ev.target.files[0]);
        }
        // if we just uploaded a photo, we want to keep track to know it's not a webcam photo
        setTookWebcamPhoto(false);
    };

    const uploadPicture = async (file?: any, data?: string) => {
        setSavingPicture(true);
        let result = await dispatch(
            saveProfilePicture({
                userProfile: userProfile,
                file: file,
                data: data,
            }),
        );
        const { payload } = result as any;
        setExistingPicture(payload);
        setSavingPicture(false);
    };

    const TemporaryPicture = () => (
        <div
            className={classes.temporaryPicture}
            onClick={() => {
                setWebCamEnabled(true);
            }}
        >
            <p style={{ margin: 10 }}>Click here to allow us temporary access to your camera</p>
        </div>
    );

    const renderWebcamButton = () => {
        return (
            <Button
                variant="primary"
                onClick={() => {
                    setWebCamEnabled(true);
                }}
                className={classes.webCamButton}
                size="small"
                fullWidth
            >
                <span
                    className="material-symbols-rounded"
                    style={{
                        marginRight: theme.spacing(1),
                        fontSize: theme.typography.pxToRem(22),
                    }}
                >
                    add_a_photo
                </span>
                Take photo
            </Button>
        );
    };

    const renderUploadButton = () => {
        return (
            <>
                <input
                    type="file"
                    id="avatarUpload"
                    accept="image/png, image/jpeg, image/jpg"
                    data-testid="profileFileUpload"
                    hidden
                    onChange={onPictureUpload}
                />
                <label htmlFor="avatarUpload">
                    <Button
                        variant="primary"
                        className={classes.webCamButton}
                        component="span"
                        size="small"
                        fullWidth
                    >
                        <span
                            className="material-symbols-rounded"
                            style={{
                                marginRight: theme.spacing(1),
                                fontSize: theme.typography.pxToRem(22),
                            }}
                        >
                            upload
                        </span>
                        Upload
                    </Button>
                </label>
            </>
        );
    };

    const renderProfilePic = () => {
        if (!webCamEnabled) {
            if (tookWebcamPhoto) {
                return (
                    <Box className={classes.photoContainer}>
                        <Avatar className={classes.avatar} src={webCamImage} />
                        {renderWebcamButton()}
                    </Box>
                );
            } else if (existingPicture) {
                return (
                    <Box className={classes.photoContainer}>
                        <Avatar
                            className={classes.avatar}
                            src={existingPicture}
                            data-testid="uploadedPicture"
                        />
                        <Box className={classes.photoButtonContainer}>
                            {renderWebcamButton()}
                            {renderUploadButton()}
                        </Box>
                    </Box>
                );
            } else {
                return (
                    <Box className={classes.photoContainer}>
                        <TemporaryPicture />
                        <Box className={classes.photoButtonContainer}>
                            {renderWebcamButton()}
                            {renderUploadButton()}
                        </Box>
                    </Box>
                );
            }
        } else {
            return <WebcamCapture />;
        }
    };

    return (
        <div>
            {savingPicture ? (
                <div className={classes.avatarLoading}>
                    <CircularProgress />
                </div>
            ) : (
                renderProfilePic()
            )}
        </div>
    );
};

export default ProfilePicture;
