import { Box } from "@mui/material";
import { theme } from "app/theme";
import PageWrapper from "components/PageWrapper";

interface HeroWithImageBackgroundProps {
    children: React.ReactNode;
    imageUrl: string;
}

export const HeroWithImageBackground = (props: HeroWithImageBackgroundProps) => {
    const { children, imageUrl } = props;

    return (
        <Box
            sx={{
                position: "relative",
                display: "flex",
                color: theme.palette.neutralWhite,
                p: {
                    xs: 2,
                    md: 6,
                },
                mb: {
                    xs: 1,
                    md: 2,
                },
            }}
        >
            {/* Overlay */}
            <Box
                sx={{
                    backgroundColor: "rgba(0, 0, 0, 0.6)",
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    zIndex: 2,
                }}
            />

            {/* Hero image */}
            <img
                style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    zIndex: 1,
                }}
                src={imageUrl}
                alt="hero"
            />

            {/* Content Container */}
            <Box sx={{ zIndex: 3, width: "100%" }}>
                <PageWrapper removeTopAndBottomMargins>{children}</PageWrapper>
            </Box>
        </Box>
    );
};
