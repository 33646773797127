import { ProgressBar } from "features/signup/ProgressBar";

export enum SignupStep {
    ACCOUNT = "ACCOUNT",
    CHECKOUT = "CHECKOUT",
}

export const SignupProgressBar = (props: { currentStep: number; onStepClick?: () => void }) => {
    const signupStepData = {
        [SignupStep.ACCOUNT]: { label: "Create your account" },
        [SignupStep.CHECKOUT]: { label: "Checkout" },
    };

    const signupStepOrder = [SignupStep.ACCOUNT, SignupStep.CHECKOUT];

    return (
        <ProgressBar
            {...props}
            stepData={signupStepData}
            stepOrder={signupStepOrder}
            nonClickableSteps={[SignupStep.ACCOUNT]}
        />
    );
};
