import { BRAND_NAME } from "@app/shared/constants";
import { Helmet } from "react-helmet";

export const DocumentContext = (props: {
    title?: string;
    description?: string;
    addBrandNameSuffix?: boolean;
}) => {
    const {
        title = "Expert guidance to deepen your mindfulness practice in a supportive and diverse community",
        description = `${BRAND_NAME} mentorship groups led by expert meditation teachers provide the supportive and safe spaces to help you build and strengthen your mindfulness meditation practice in a diverse and inclusive community.`,
    } = props;

    return (
        <Helmet>
            <meta charSet="utf-8" />
            <title>{props.addBrandNameSuffix ? `${title} - ${BRAND_NAME}` : title}</title>
            <meta name="description" content={description} />

            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
        </Helmet>
    );
};

export default DocumentContext;
