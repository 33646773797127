import ExpandLessRoundedIcon from "@mui/icons-material/ExpandLessRounded";
import { alpha, darken, IconButton, Tooltip, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { localImageSrc } from "app/images";
import { theme } from "app/theme";
import { useBreakpointQuery } from "hooks/useBreakPointQuery";

export const SANGHA_MEMBERS_HIDDEN_HEIGHT = 42;

const getBackgroundStyle = (width?: number) =>
    `linear-gradient(${alpha(theme.palette.grey900, 0.15)}, ${alpha(
        theme.palette.grey900,
        0.15,
    )}), url(${localImageSrc("bg2-min.jpg", width)})`;

const useStyles = makeStyles((theme) => ({
    container: {
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: darken(theme.palette.primaryLeaves, 0.1),
        backgroundSize: "cover",
        backgroundPosition: "50% 50%",
        color: theme.palette.neutralWarm,
        cursor: "pointer",
    },
    linkContainer: {
        margin: theme.spacing(2, 0, 1, 0),
        textAlign: "center",
    },
    link: {
        cursor: "pointer",
    },
    openButton: {
        position: "absolute",
        left: "50%",
        top: 0,
        transform: "translateX(-50%) translateY(-40%)",
        color: theme.palette.neutralWhite,
        backgroundColor: theme.palette.accentGold,
        padding: 0,
        "&:hover": {
            backgroundColor: theme.palette.accentEarthy,
        },
    },
}));

export const SanghaMembersHidden = (props: { onOpen: VoidFunction }) => {
    const classes = useStyles();
    const isMobile = useBreakpointQuery("sm");

    return (
        <div className={classes.container} onClick={props.onOpen}>
            <Tooltip title="Show members">
                <IconButton
                    classes={{ root: classes.openButton }}
                    data-testid="showSanghaMembersButton"
                >
                    <ExpandLessRoundedIcon />
                </IconButton>
            </Tooltip>

            <Typography variant="body2" className={classes.linkContainer}>
                <span className={classes.link}>
                    <strong>{isMobile ? "Sangha details" : "See my group"}</strong>
                </span>
            </Typography>
        </div>
    );
};
