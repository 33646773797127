import { Typography } from "@mui/material";
import PageWrapper from "components/PageWrapper";
import { Redirect, useLocation } from "react-router";
import { MentorshipGroupList } from "./MentorshipGroupList";
import { MentorshipGroupFilter } from "./MentorshipGroupFilter";
import { useQueryParams } from "use-query-params";
import { CustomArrayParam } from "../filters/customQueryParamDefinitions";
import { GRAPHQL_QUERY_PUBLICALLY_AVAILABLE_IMMERSION_GROUPS } from "app/queries";
import { useQuery } from "@apollo/client";
import { GenericErrorMessage } from "components/GenericErrorMessage";
import { Sangha } from "@app/shared/types";
import { usePreferredGroup } from "hooks/usePreferredGroup";
import { filterSanghas } from "../filters/filterHelpers";
import { useUserTimezone } from "hooks/useUserTimezone";
import LoadingPage from "features/pages/LoadingPage";
import { HeroWithImageBackground } from "layouts/HeroWithImageBackground";
import { useSelector } from "react-redux";
import { selectCanAccessMemberZone } from "features/auth/auth";
import { routes } from "app/routes";

export const AddOnMentorshipGroupPage = () => {
    const timezone = useUserTimezone();

    const canAccessMemberZone = useSelector(selectCanAccessMemberZone);
    const currentLocation = useLocation();

    const [query, setQuery] = useQueryParams({
        timeOfDay: CustomArrayParam,
        dayOfWeek: CustomArrayParam,
        teacher: CustomArrayParam,
        audience: CustomArrayParam,
    });

    const preferredGroupId = usePreferredGroup();

    const {
        data: groupData,
        loading: groupsLoading,
        error: groupsError,
    } = useQuery(GRAPHQL_QUERY_PUBLICALLY_AVAILABLE_IMMERSION_GROUPS);

    if (currentLocation.pathname === routes.publicMentorshipGroups() && canAccessMemberZone) {
        return <Redirect to={routes.memberSanghas()} />;
    }

    if (groupsError) {
        return <GenericErrorMessage />;
    }

    let availableGroups: Sangha[] | undefined = groupData?.availableImmersionGroups;

    if (groupsLoading || !availableGroups) {
        return <LoadingPage />;
    }

    availableGroups = availableGroups
        .filter((x) => x.nextSession)
        .sort((a, b) => b.openSpots - a.openSpots);

    // List preferred group on top
    const preferredGroups = availableGroups.filter((x) => x.id === preferredGroupId);
    if (preferredGroups.length > 0) {
        availableGroups = [
            ...preferredGroups,
            ...availableGroups.filter((x) => x.id !== preferredGroupId),
        ];
    }

    const resetFilters = () => {
        setQuery({
            timeOfDay: undefined,
            dayOfWeek: undefined,
            teacher: undefined,
            audience: undefined,
        });
    };

    const filteredSanghas = availableGroups
        ? filterSanghas(availableGroups, query, timezone)
        : undefined;

    return (
        <>
            <HeroWithImageBackground imageUrl="https://assets.cloudsangha.co/images/plants.jpeg">
                <Typography variant="h1" sx={{ mb: 0 }}>
                    Mentorship Groups
                </Typography>
                <Typography variant="body1">
                    These small, closed groups mean you meet with the same teacher and peers each
                    week for personal support and deep practice.
                </Typography>
            </HeroWithImageBackground>
            <PageWrapper smallPaddingTop>
                <MentorshipGroupFilter
                    filters={query}
                    setFilters={setQuery}
                    groupCount={0}
                    resetFilters={resetFilters}
                    groups={availableGroups}
                />

                <MentorshipGroupList groups={filteredSanghas || availableGroups} />
            </PageWrapper>
        </>
    );
};
