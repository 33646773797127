import { Box, Button, Typography } from "@mui/material";
import { NAVBAR_HEIGHT } from "app/styles";
import { theme } from "app/theme";
import PageWrapper from "components/PageWrapper";
import { FixedCTAContainer } from "features/signup/FixedCTAContainer";

export interface SetupWelcomePageProps {
    onContinue: () => void;
}

export const SetupWelcomePage = (props: SetupWelcomePageProps) => {
    const { onContinue } = props;

    return (
        <Box
            sx={{
                backgroundColor: theme.palette.primaryLeaves,
                color: theme.palette.neutralWhite,
                minHeight: `calc(100vh - ${NAVBAR_HEIGHT}px)`,
                height: "100%",
            }}
        >
            <PageWrapper removeMarginTop>
                <Typography variant="h2">Welcome to Banyan 🎉</Typography>
                <Box sx={{ paddingTop: "56.25%", position: "relative" }}>
                    {/* paddingTop: 56.25% results in a 16:9 aspect ratio with full width */}
                    <iframe
                        src="https://player.vimeo.com/video/1036473968?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                        allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                        style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            borderRadius: theme.borderRadius.default,
                            border: 0,
                        }}
                        title="Welcome to Banyan"
                    ></iframe>
                </Box>
                <script src="https://player.vimeo.com/api/player.js"></script>
                <FixedCTAContainer>
                    <Button
                        variant="primary"
                        onClick={onContinue}
                        fullWidth
                        data-testid="continueButton"
                    >
                        Continue
                    </Button>
                </FixedCTAContainer>
            </PageWrapper>
        </Box>
    );
};
