import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TuneIcon from "@mui/icons-material/Tune";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Drawer,
    IconButton,
    Tooltip,
    Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { NAVBAR_HEIGHT, NAVBAR_HEIGHT_MOBILE } from "app/styles";
import { useDetectScroll } from "hooks/useDetectScroll";
import { useElementPosition } from "hooks/useElementPosition";
import { useRef, useState } from "react";

export type FilterConfig = {
    title: string;
    render: JSX.Element;
    getAppliedCount: () => number;
    showWithoutNesting?: boolean;
    openByDefault?: boolean;
};

interface FilterSidebarProps {
    filters: FilterConfig[];
    groupCount?: number;
    resetFilters: VoidFunction;
}

const useStyles = makeStyles((theme) => ({
    paper: {
        width: "40%",
        backgroundColor: theme.palette.neutralWhite,
        paddingLeft: theme.spacing(6),
        paddingRight: theme.spacing(6),
        [theme.breakpoints.down("lg")]: {
            width: "100%",
            paddingLeft: theme.spacing(12),
            paddingRight: theme.spacing(12),
        },
        [theme.breakpoints.down("md")]: {
            paddingLeft: theme.spacing(6),
            paddingRight: theme.spacing(6),
        },
    },
    backButton: {
        height: theme.spacing(5),
        width: theme.spacing(5),
        marginTop: theme.spacing(2),
    },
    backButtonIcon: {
        fontSize: theme.typography.pxToRem(30),
    },
    noMargin: {
        margin: 0,
    },
    filterButton: {},
    filterBarButton: {
        color: theme.palette.grey900,
    },
    filterIcon: {
        marginRight: theme.spacing(1),
        [theme.breakpoints.down("lg")]: {
            fontSize: theme.typography.pxToRem(20),
        },
    },
    buttonContainer: {
        display: "flex",
        justifyContent: "flex-end",
        position: "relative",
    },
    filterBar: {
        opacity: 0,
        pointerEvents: "none",

        position: "fixed",
        transition: "all 0.3s ease-in-out",
        top: NAVBAR_HEIGHT + 8,
        backgroundColor: theme.palette.accentPeach,
        width: "100%",
        left: 0,
        height: theme.spacing(6),
        boxShadow: theme.shadow.darker,
        zIndex: theme.zIndex.appBar,
        [theme.breakpoints.down("lg")]: {
            top: NAVBAR_HEIGHT_MOBILE + 8,
        },
    },
    filterBarWrapper: {
        maxWidth: "1272px",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        margin: "0 auto",
        paddingLeft: theme.spacing(6),
        paddingRight: theme.spacing(6),
        [theme.breakpoints.down("lg")]: {
            maxWidth: "100%",
        },
        [theme.breakpoints.down("md")]: {
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3),
        },
    },
    fullOpacity: {
        opacity: 1,
        pointerEvents: "auto",
    },
    filterDrawerHeader: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: theme.spacing(2),
    },
    filterDrawerTopBar: {
        position: "sticky",
        top: 0,
        zIndex: theme.zIndex.appBar,
        backgroundColor: theme.palette.neutralWhite,
        marginBottom: theme.spacing(2),
        boxShadow: theme.shadow.default,
        // adjusting to the padding of the parent element
        marginLeft: theme.spacing(-6),
        marginRight: theme.spacing(-6),
        paddingLeft: theme.spacing(6),
        paddingRight: theme.spacing(6),
        [theme.breakpoints.down("lg")]: {
            marginLeft: theme.spacing(-12),
            marginRight: theme.spacing(-12),
        },
        [theme.breakpoints.down("md")]: {
            marginLeft: theme.spacing(-6),
            marginRight: theme.spacing(-6),
        },
    },
}));

export const FilterSidebar = (props: FilterSidebarProps) => {
    const classes = useStyles();
    const { filters, groupCount, resetFilters } = props;

    const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false);

    const filterButtonRef = useRef(null);
    const filterButtonPosition = useElementPosition(filterButtonRef);

    const numFiltersApplied = filters.reduce(
        (total, filter) => total + filter.getAppliedCount(),
        0,
    );

    const showFilterBar = useDetectScroll({
        threshold: filterButtonPosition,
        hideOnScrollUp: true,
    });

    const handleBackButtonClick = () => {
        setIsFilterMenuOpen(false);
    };

    return (
        <>
            <div className={`${classes.filterBar} ${showFilterBar && classes.fullOpacity}`}>
                <div className={classes.filterBarWrapper}>
                    <span>
                        {groupCount} {groupCount === 1 ? "group" : "groups"} found
                    </span>
                    <Button
                        onClick={() => setIsFilterMenuOpen(!isFilterMenuOpen)}
                        className={classes.filterBarButton}
                    >
                        <TuneIcon className={classes.filterIcon} /> Filter ({numFiltersApplied})
                    </Button>
                </div>
            </div>

            <div className={classes.buttonContainer}>
                <Button
                    onClick={() => setIsFilterMenuOpen(!isFilterMenuOpen)}
                    variant="secondary"
                    size="small"
                    className={classes.filterButton}
                    ref={filterButtonRef}
                >
                    <TuneIcon className={classes.filterIcon} /> Filter ({numFiltersApplied})
                </Button>
            </div>

            <Drawer
                anchor="right"
                open={isFilterMenuOpen}
                onClose={() => setIsFilterMenuOpen(false)}
                classes={{ paper: classes.paper }}
            >
                <div className={classes.filterDrawerTopBar}>
                    <Tooltip title="Close filter">
                        <IconButton
                            onClick={handleBackButtonClick}
                            aria-label="close filter"
                            className={classes.backButton}
                        >
                            <ArrowBackIcon className={classes.backButtonIcon} />
                        </IconButton>
                    </Tooltip>

                    <div className={classes.filterDrawerHeader}>
                        <Typography variant="h4" className={classes.noMargin}>
                            Filter ({numFiltersApplied})
                        </Typography>
                        {numFiltersApplied > 0 && (
                            <Button variant="tertiary" onClick={() => resetFilters()}>
                                Clear
                            </Button>
                        )}
                    </div>
                </div>
                <div>
                    {filters.map((filter, index) => (
                        <Accordion key={index}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="h4">{filter.title}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>{filter.render}</AccordionDetails>
                        </Accordion>
                    ))}
                </div>
            </Drawer>
        </>
    );
};
