import { DocumentNode, useMutation } from "@apollo/client";
import { Sangha } from "@app/shared/types";
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
import { Button, CircularProgress, MenuItem, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { analyticsTrack } from "app/analytics/track";
import {
    GRAPHQL_MUTATION_CANCEL_INTEREST_GROUP_MEMBERSHIP,
    GRAPHQL_QUERY_AVAILABLE_INTEREST_GROUPS,
    GRAPHQL_QUERY_EVENT_RSVP_USERS,
    GRAPHQL_QUERY_MY_SANGHAS,
    GRAPHQL_QUERY_MY_SCHEDULE,
} from "app/queries";
import { routes } from "app/routes";
import { showSnackbarAlertOnRedirect } from "components/AlertSnackBar";
import { CSDialog } from "components/CSDialog";
import { push } from "connected-react-router";
import { useState } from "react";
import { useDispatch } from "react-redux";
import smoothscroll from "smoothscroll-polyfill";

smoothscroll.polyfill();

const useStyles = makeStyles((theme) => ({
    buttonContainer: {
        float: "right",
    },
    menuItem: {
        marginBottom: 0,
    },
    menuItemIcon: {
        marginRight: theme.spacing(2),
    },
}));

export const LeaveGroupMenuItem = (props: {
    sangha: Sangha;
    sanghaMembershipId: string;
    eventId?: string;
    renderAsButton?: boolean;
}) => {
    const classes = useStyles();
    const [showDialog, setShowDialog] = useState(false);
    const dispatch = useDispatch();

    const [isCancelling, setIsCancelling] = useState(false);

    const refetchQueries: Array<{ query: DocumentNode; variables?: { id: string } }> = [
        {
            query: GRAPHQL_QUERY_MY_SANGHAS,
        },
        {
            query: GRAPHQL_QUERY_AVAILABLE_INTEREST_GROUPS,
        },
        {
            query: GRAPHQL_QUERY_MY_SCHEDULE,
        },
    ];

    if (props.eventId) {
        refetchQueries.push({
            query: GRAPHQL_QUERY_EVENT_RSVP_USERS,
            variables: { id: props.eventId },
        });
    }

    const [cancelMfgMembership] = useMutation(GRAPHQL_MUTATION_CANCEL_INTEREST_GROUP_MEMBERSHIP, {
        refetchQueries,
    });

    const getAnalyticsMetadata = () => ({
        sanghaId: props.sangha.id,
        sanghaName: props.sangha.name,
        sanghaMembershipId: props.sanghaMembershipId,
    });

    return (
        <>
            {props.renderAsButton ? (
                <Button
                    variant="secondary"
                    size="small"
                    onClick={(event: any) => {
                        analyticsTrack("leaveGroup.modal.opened", getAnalyticsMetadata());
                        setShowDialog(true);
                    }}
                    fullWidth
                >
                    Leave group
                </Button>
            ) : (
                <MenuItem
                    data-testid="leaveGroupButton"
                    onClick={(event: any) => {
                        analyticsTrack("leaveGroup.modal.opened", getAnalyticsMetadata());
                        setShowDialog(true);
                    }}
                    className={classes.menuItem}
                >
                    <ExitToAppOutlinedIcon className={classes.menuItemIcon} /> Leave group
                </MenuItem>
            )}
            <CSDialog
                open={showDialog}
                onClose={() => {
                    analyticsTrack("leaveGroup.modal.cancel", getAnalyticsMetadata());
                    setShowDialog(false);
                }}
            >
                <Typography variant="h2">Are you sure you'd like to leave this group?</Typography>
                <Typography variant="body1">
                    You will no longer be able to access group's private chat page.
                </Typography>
                {isCancelling && <CircularProgress />}

                <div className={classes.buttonContainer}>
                    <Button
                        variant="secondary"
                        onClick={async () => {
                            analyticsTrack("leaveGroup.modal.cancel", getAnalyticsMetadata());
                            setShowDialog(false);
                        }}
                    >
                        Cancel
                    </Button>{" "}
                    <Button
                        variant="primary"
                        onClick={async () => {
                            analyticsTrack("leaveGroup.modal.submit", getAnalyticsMetadata());
                            setIsCancelling(true);
                            await cancelMfgMembership({
                                variables: {
                                    sanghaMembershipId: props.sanghaMembershipId,
                                },
                            });
                            setIsCancelling(false);
                            setShowDialog(false);
                            showSnackbarAlertOnRedirect(
                                `You've successfully left the ${props.sangha.name} group.`,
                            );

                            dispatch(push(routes.memberZone()));
                        }}
                        data-testid="comfirmLeaveButton"
                    >
                        Leave group
                    </Button>
                </div>
            </CSDialog>
        </>
    );
};
