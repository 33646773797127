import { ALL_ONBOARDING_ITEMS } from "@app/shared/constants";
import { Button, IconButton, Tooltip, Typography, useMediaQuery } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { theme } from "app/theme";
import classNames from "classnames";
import { LoggedInUserProfileContext } from "features/auth/RequireAuthentication";
import { DateTime } from "luxon";
import { useContext, useEffect, useRef, useState } from "react";
import { MAX_HEIGHT_FOR_POPUP, OnboardingPopup } from "./OnboardingPopup";

const useStyles = makeStyles(() => ({
    button: {
        position: "fixed",
        left: 20,
        bottom: 30,
        zIndex: theme.zIndex.drawer,
    },
    numberBadge: {
        position: "absolute",
        top: -3,
        right: -6,
        width: 24,
        height: 24,
        borderRadius: "50%",
        backgroundColor: theme.palette.error500,
        color: "white",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    noScroll: {
        overflow: "hidden",
    },
    wiggleAnimation: {
        animation: "$wiggle 0.3s ease-in-out infinite",
    },
    "@keyframes wiggle": {
        "0%, 100%": {
            transform: "rotate(-2deg)",
        },
        "50%": {
            transform: "rotate(2deg)",
        },
    },
}));

export const OnboardingPopupButton = () => {
    const classes = useStyles();
    const windowHasSmallHeight = useMediaQuery(`(max-height: ${MAX_HEIGHT_FOR_POPUP}px)`, {
        noSsr: true,
    });

    const user = useContext(LoggedInUserProfileContext);

    const now = DateTime.now();
    const popupLastSeen = localStorage.getItem("popupLastSeen");
    const popupLastSeenDate = popupLastSeen ? DateTime.fromISO(popupLastSeen) : null;
    const showPopup =
        (!popupLastSeenDate || now.diff(popupLastSeenDate, "days").days > 2) &&
        !windowHasSmallHeight;

    const lastWiggle = localStorage.getItem("lastWiggleDate");
    const lastWiggleDate = lastWiggle ? DateTime.fromISO(lastWiggle) : null;
    const alreadyWiggledToday = lastWiggleDate && now.hasSame(lastWiggleDate, "day");
    const showWiggle =
        !alreadyWiggledToday && popupLastSeenDate && now.diff(popupLastSeenDate, "days").days > 1;
    const [shouldWiggle, setShouldWiggle] = useState(false);

    const [popupOpen, setPopupOpen] = useState(showPopup);
    const [anchorEl, setAnchorEl] = useState(null);
    const buttonRef = useRef(null);

    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const [popupWasOpenedBefore, setPopupWasOpenedBefore] = useState(false);

    const completedCount = user.profile.completedOnboardingItems?.length || 0;
    const totalItems = ALL_ONBOARDING_ITEMS.length;
    const [uncompletedCount, setUncompletedCount] = useState(totalItems - completedCount);

    const togglePopupOpen = () => {
        setPopupOpen((prevPopupOpen: boolean) => !prevPopupOpen);
    };

    useEffect(() => {
        if (popupOpen && isMobile) {
            document.body.classList.add(classes.noScroll);
        } else {
            document.body.classList.remove(classes.noScroll);
        }

        setAnchorEl(buttonRef.current);
    }, [popupOpen, isMobile]);

    useEffect(() => {
        if ((isMobile || windowHasSmallHeight) && popupOpen) {
            setPopupOpen(false);
        }
    }, [isMobile, windowHasSmallHeight]);

    useEffect(() => {
        if (!showPopup && showWiggle) {
            setShouldWiggle(true);
            localStorage.setItem("lastWiggleDate", now.toISO());
            const timer = setTimeout(() => setShouldWiggle(false), 10000);
            return () => clearTimeout(timer);
        }

        if (popupOpen) {
            localStorage.setItem("popupLastSeen", now.toISO());
        }
    }, []);

    useEffect(() => {
        if (popupOpen && !popupWasOpenedBefore) {
            setPopupWasOpenedBefore(true);
        }
    }, [popupOpen]);

    const handleItemCompletion = (newCompletedCount: number) => {
        setUncompletedCount(totalItems - newCompletedCount);
    };

    return (
        <>
            {popupOpen ? (
                <Tooltip title="Close onboarding steps">
                    <IconButton
                        color="secondary"
                        onClick={togglePopupOpen}
                        className={classes.button}
                        ref={buttonRef}
                    >
                        <span className={classNames("material-symbols-rounded")}>close</span>
                    </IconButton>
                </Tooltip>
            ) : (
                <Button
                    variant="primary"
                    onClick={togglePopupOpen}
                    className={classNames(classes.button, {
                        [classes.wiggleAnimation]: shouldWiggle,
                    })}
                    ref={buttonRef}
                    data-testid="onboardingPopupOpenButton"
                >
                    <Typography variant="body1" className={classes.numberBadge}>
                        {uncompletedCount}
                    </Typography>
                    Your first steps
                </Button>
            )}
            {(popupOpen || popupWasOpenedBefore) && (
                <OnboardingPopup
                    popupOpen={popupOpen}
                    setPopupOpen={setPopupOpen}
                    anchorEl={anchorEl}
                    onCompletionUpdate={handleItemCompletion}
                />
            )}
        </>
    );
};
