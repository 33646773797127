import { Avatar, Chip, IconButton, Tooltip, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { externalImageSrc } from "app/images";
import { NavLink } from "features/navigation/NavLink";
import _ from "lodash";
import * as React from "react";

const useStyles = makeStyles((theme) => ({
    card: {
        position: "relative",
        backgroundColor: theme.palette.neutralCool,
        color: theme.palette.grey900,
        borderRadius: theme.borderRadius.default,
        padding: theme.spacing(6),
        margin: theme.spacing(4, "auto"),
        maxWidth: 800,
        display: "flex",
        cursor: "pointer",

        [theme.breakpoints.down("md")]: {
            flexDirection: "column",
        },
    },
    avatar: {
        width: 260,
        height: 200,
        borderRadius: theme.borderRadius.default,
        marginRight: theme.spacing(10),

        [theme.breakpoints.down("sm")]: {
            width: 200,
            height: 154,
        },
    },
    content: {},
    title: {
        marginTop: theme.spacing(4),
    },
    name: {
        display: "inline-block",
        margin: 0,
        marginRight: theme.spacing(1),
    },
    pronouns: {
        display: "inline-block",
        margin: 0,
    },
    location: {
        marginBottom: theme.spacing(2),
    },
    bio: {
        whiteSpace: "pre-line",
        overflowWrap: "break-word",
    },
    teacherLabel: {
        position: "absolute",
        left: 0,
        top: 0,
        transform: "translateX(-30%) translateY(-20%)",

        [theme.breakpoints.down("md")]: {
            transform: "translateX(-20%) translateY(-20%)",
        },
    },
    websiteLink: {
        color: theme.palette.accentGold,
        position: "absolute",
        top: theme.spacing(2),
        right: theme.spacing(2),
    },
    iconButton: {
        position: "absolute",
        top: theme.spacing(3),
        right: theme.spacing(3),
        color: theme.palette.primaryBanyan,
        padding: 0,
        "&:hover": {
            color: theme.palette.accentGold,
        },
        "& .material-symbols-rounded": {
            fontSize: theme.typography.pxToRem(30),
        },
    },
}));

export interface ProfileCommon {
    nickName: string;
    picture: string | null;
    bio: string | null;
    pronouns: string | null;
    location: string | null;
    isTeacher?: boolean;
    website?: string | null;
}

export const UserProfileHighlight = (props: {
    profile: ProfileCommon;
    iconButton?: {
        src: string;
        alt: string;
    };
    onClick?: () => void;
    classes?: Partial<ReturnType<typeof useStyles>>;
}) => {
    const classes = useStyles(props);

    const { profile, onClick, iconButton } = props;
    const shortenedBio = _.truncate(profile.bio || "", {
        length: 90,
        separator: " ",
    });

    const onClickCard = (ev: React.MouseEvent) => {
        const target = ev.target as HTMLElement;
        // Skip the click handler if we click a link
        if (target.tagName !== "A" && onClick) {
            onClick();
        }
    };

    return (
        <div className={classes.card} data-testid="userProfileCard" onClick={onClickCard}>
            {profile.isTeacher && (
                <Chip variant="darkGreen" label="Your teacher" className={classes.teacherLabel} />
            )}
            {profile.isTeacher && profile.website && (
                <NavLink
                    className={classes.websiteLink}
                    to={profile.website}
                    target="_blank"
                    rel="external nofollow"
                >
                    See personal site
                </NavLink>
            )}

            {iconButton && (
                <Tooltip title="Edit profile">
                    <IconButton className={classes.iconButton}>
                        <span className="material-symbols-rounded">edit</span>
                    </IconButton>
                </Tooltip>
            )}

            <Avatar
                data-testid="userProfileAvatar"
                className={classes.avatar}
                alt={profile.nickName}
                src={profile.picture ? externalImageSrc(profile.picture, 260) : "EMPTY"}
            />

            <div className={classes.content}>
                <div className={classes.title}>
                    <Typography variant="h3" className={classes.name} data-testid="userNickName">
                        {profile.nickName}
                    </Typography>
                    {profile.pronouns && (
                        <Typography variant="subtitle1" className={classes.pronouns}>
                            {profile.pronouns}
                        </Typography>
                    )}
                </div>
                {profile.location && (
                    <Typography variant="caption" className={classes.location}>
                        <strong>{profile.location}</strong>
                    </Typography>
                )}

                <Typography variant="body1" className={classes.bio}>
                    {shortenedBio}
                </Typography>
            </div>
        </div>
    );
};

export default UserProfileHighlight;
