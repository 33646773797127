import { Theme } from "@mui/material";
import { styled } from "@mui/system";

export const FixedCTAContainer = styled("div")(({ theme }) => ({
    display: "flex",
    gap: theme.spacing(4),
    position: "fixed",
    bottom: theme.spacing(8),
    right: theme.spacing(10),
    zIndex: (theme as Theme).zIndex.speedDial,

    [theme.breakpoints.down("md")]: {
        justifyContent: "center",
        left: 0,
        width: "100%",
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },

    [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        bottom: theme.spacing(4),
    },
}));
