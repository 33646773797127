import makeStyles from "@mui/styles/makeStyles";
import { selectLoginAs } from "features/auth/auth";
import { AppFooterLoader } from "features/navigation/AppFooterLoader";
import { connect, useSelector } from "react-redux";
import { LoginDialog } from "./features/auth/LoginDialog";
import { FullPageMenu } from "./features/navigation/FullPageMenu";
import { TopBar } from "./features/navigation/TopBar";
import { Routes } from "./Routes";

const useStyles = makeStyles((theme) => {
    return {
        mainContainer: {
            backgroundColor: theme.palette.background.default,
        },

        pageContainer: {
            position: "relative",
        },

        pageContainerOverlay: {
            position: "absolute",
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            backgroundColor: theme.palette.background.default,
            opacity: 0.3,
        },

        pageContainerInner: {
            position: "relative",
        },

        warningAlert: {
            backgroundColor: theme.palette.error.dark,
            color: theme.palette.neutralWarm,
            textAlign: "center",
            position: "fixed",
            left: 0,
            top: 0,
            right: 0,
            zIndex: theme.zIndex.appBar + 1,
        },
    };
});

export const MainContainer = () => {
    const classes = useStyles();

    const loginAs = useSelector(selectLoginAs);

    const renderLoginAsWarning = () => {
        // Show a banner with a warning if an admin user is logged in as another user
        if (loginAs) {
            return <div className={classes.warningAlert}>You are logged in as: {loginAs}</div>;
        }
    };

    return (
        <div className={classes.mainContainer}>
            {renderLoginAsWarning()}
            <FullPageMenu />
            <LoginDialog />
            <div className={classes.pageContainer}>
                <div className={classes.pageContainerOverlay} />
                <div className={classes.pageContainerInner}>
                    <TopBar />

                    <Routes />
                </div>
            </div>
            <AppFooterLoader />
        </div>
    );
};

export default connect()(MainContainer);
