import {
    CUSTOM_PATH_TYPEFORM_URL_DEV,
    CUSTOM_PATH_TYPEFORM_URL_PRODUCTION,
} from "@app/shared/constants";
import { analyticsTrack } from "app/analytics/track";
import { isProduction } from "app/config";
import { LinkButton } from "features/navigation/LinkButton";
import { DateTime } from "luxon";

const CUSTOM_PATH_TYPEFORM_BASE_URL = isProduction()
    ? CUSTOM_PATH_TYPEFORM_URL_PRODUCTION
    : CUSTOM_PATH_TYPEFORM_URL_DEV;

type RefreshIntentionButtonProps = {
    userId: string;
    userEmail: string;
    customPathGoals: string[];
    fullIntention: string;
};

export const RefreshIntentionButton = (props: RefreshIntentionButtonProps): JSX.Element => {
    const { userEmail, userId, customPathGoals, fullIntention } = props;
    const encodedIntention = encodeURIComponent(fullIntention);
    const encodedUserId = encodeURIComponent(userId);
    const encodedUserEmail = encodeURIComponent(userEmail);
    const formSubmittedDate = Math.floor(DateTime.now().toSeconds());

    let reflectionTypeformLink = `${CUSTOM_PATH_TYPEFORM_BASE_URL}#is_reflection=true&intention_form_last_submitted=${formSubmittedDate}&user_id=${encodedUserId}&email=${encodedUserEmail}&prior_intention=${encodedIntention}`;

    if (customPathGoals[0]) {
        const customPathGoal1 = encodeURIComponent(customPathGoals[0]);
        reflectionTypeformLink += `&goal_1=${customPathGoal1}`;
    }

    if (customPathGoals[1]) {
        const customPathGoal2 = encodeURIComponent(customPathGoals[1]);
        reflectionTypeformLink += `&goal_2=${customPathGoal2}`;
    }

    if (customPathGoals[2]) {
        const customPathGoal3 = encodeURIComponent(customPathGoals[2]);
        reflectionTypeformLink += `&goal_3=${customPathGoal3}`;
    }

    return (
        <LinkButton
            data-testid="refreshIntentionButton"
            to={reflectionTypeformLink}
            fullWidth={false}
            variant="primary"
            size="small"
            onClick={() => {
                analyticsTrack("Refresh Intention Button Clicked", {
                    userId,
                    customPathGoals,
                    currentIntention: fullIntention,
                    typeformLink: reflectionTypeformLink,
                });
            }}
        >
            Refresh intentions
        </LinkButton>
    );
};
