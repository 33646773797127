/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-restricted-globals */
import { ModalRoutes } from "ModalRoutes";
import { AlertSnackBar } from "components/AlertSnackBar";
import { CourseEmbeddedLandingPage } from "features/courses/CourseEmbeddedLandingPage";
import { CoursePaymentSuccess } from "features/courses/CoursePaymentSuccess";
import { CourseSignupFlow } from "features/courses/CourseSignupFlow";
import { CoursesDashboard } from "features/courses/CoursesDashboard";
import { PageRedirect } from "features/landingPage/PageRedirect";
import { DiversityAtCloudSangha } from "features/landingPage/sep22/DiversityAtCloudSangha";
import { InterestGroupFlow } from "features/member/interestGroups/InterestGroupFlow";
import { SetupFlow } from "features/setup/SetupFlow";
import { TeacherPage } from "features/member/teachers/TeacherPage";
import { TeachersPage } from "features/member/teachers/TeachersPage";
import { AuthorizePage } from "features/oauth/AuthorizePage";
import { CookiePolicyPage } from "features/pages/CookiePolicyPage";
import { IntentionsSubmittedPage } from "features/pages/IntentionsSubmittedPage";
import NotFoundPage from "features/pages/NotFoundPage";
import { VisitorDonate } from "features/pages/VisitorDonate";
import { VisitorDonateSuccess } from "features/pages/VisitorDonateSuccess";
import { SignupFlow } from "features/signup";
import { SignupSuccess } from "features/signup/SignupSuccess";
import { TeacherPostSessionFlow } from "features/teacherPostSessionFlow/TeacherPostSessionFlow";
import { SubstituteRequestsPage } from "features/teacherSubstitutes/SubstituteRequestsPage";
import { ZoomAppTestPage } from "features/test/ZoomAppTestPage";
import { Route, Switch, useLocation } from "react-router";
import { routes } from "./app/routes";
import { AuthRoute } from "./features/auth/AuthRoute";
import { Login } from "./features/auth/Login";
import ContactPage from "./features/contact/ContactPage";
import MemberZone from "./features/member/MemberZone";
import FAQPage from "./features/pages/FAQPage";
import GuidelinesPage from "./features/pages/GuidelinesPage";
import MaintenancePage from "./features/pages/MaintenancePage";
import PrivacyPolicyPage from "./features/pages/PrivacyPolicyPage";
import TermsOServicePage from "./features/pages/TermsOfServicePage";
import { AddOnMentorshipGroupPage } from "features/member/addOnMentorshipGroup/AddOnMentorshipGroupPage";
import { ReviewMentorshipAddOn } from "features/member/addOnMentorshipGroup/ReviewMentorshipAddOn";

/**
 * WARNING: If you add new top level routes to the react app, they will work locally but will NOT work in production
 * unless you configure our load balancer to recognize them. See "Site structure and routing" in the README for instructions
 */

export const Routes = (props: any) => {
    const maintenance = false;

    const location = useLocation();

    return (
        <>
            <AlertSnackBar />

            <Switch>
                <Route
                    path={routes.publicAddMentorshipGroup(":sanghaId")}
                    component={ReviewMentorshipAddOn}
                />
                <Route
                    path={routes.publicMentorshipGroups()}
                    component={AddOnMentorshipGroupPage}
                />
                <Route path={routes.communityGuidelines()} component={GuidelinesPage} />
                <Route path={routes.contactUs()} component={ContactPage} />
                <Route path={routes.faq()} component={FAQPage} />
                <Route path={routes.privacyPolicy()} component={PrivacyPolicyPage} />
                <Route path={routes.cookiePolicy()} component={CookiePolicyPage} />
                <Route path={routes.termsOfService()} component={TermsOServicePage} />

                <AuthRoute
                    path={routes.signupSuccess()}
                    component={!maintenance ? SignupSuccess : MaintenancePage}
                />

                <Route
                    path={routes.signup()}
                    component={!maintenance ? SignupFlow : MaintenancePage}
                />
                <Route path={routes.login()} component={!maintenance ? Login : MaintenancePage} />
                <Route
                    path={routes.oauthAuthorize()}
                    component={!maintenance ? AuthorizePage : MaintenancePage}
                />

                <AuthRoute path={routes.setupFlow()} component={SetupFlow} />

                <Route path={routes.allInterestGroups()} component={InterestGroupFlow} />

                <AuthRoute
                    path={routes.memberZone()}
                    component={!maintenance ? MemberZone : MaintenancePage}
                />

                <AuthRoute
                    path={routes.teacherPostSession(":sanghaId")}
                    component={!maintenance ? TeacherPostSessionFlow : MaintenancePage}
                />
                <AuthRoute path={routes.teacherZoomAppTestPage()} component={ZoomAppTestPage} />
                <AuthRoute
                    path={routes.teacherSubstituteRequests()}
                    component={SubstituteRequestsPage}
                />

                <Route exact path={routes.diversity()} component={DiversityAtCloudSangha} />

                <AuthRoute
                    path={routes.coursePaymentSuccess(":courseId")}
                    component={CoursePaymentSuccess}
                />

                <Route path={routes.courseSignup(":courseId")} component={CourseSignupFlow} />
                <Route
                    path={routes.courseEmbeddedLandingPage(":courseId")}
                    component={CourseEmbeddedLandingPage}
                />

                <AuthRoute path={routes.coursesDashboard()} component={CoursesDashboard} />
                <Route path={routes.coursesPublicDashboard()} component={CoursesDashboard} />

                {/* Webflow pages */}
                <Route
                    exact
                    path={routes.home()}
                    render={(props) => <PageRedirect {...props} pageName="landingPage" />}
                />
                <Route
                    exact
                    path={routes.testimonials()}
                    render={(props) => <PageRedirect {...props} pageName="testimonials" />}
                />
                <Route
                    exact
                    path={routes.publicTeachersLandingPage()}
                    render={(props) => (
                        <PageRedirect {...props} pageName="publicTeachersLandingPage" />
                    )}
                />
                <Route
                    exact
                    path={routes.retreats()}
                    render={(props) => <PageRedirect {...props} pageName="retreats" />}
                />
                <Route
                    exact
                    path={routes.teacherSection()}
                    render={(props) => <PageRedirect {...props} pageName="teacherSection" />}
                />
                <Route
                    path={routes.about()}
                    render={(props) => <PageRedirect {...props} pageName="aboutPage" />}
                />
                <Route
                    path={routes.careers()}
                    render={(props) => <PageRedirect {...props} pageName="careersPage" />}
                />

                <Route path={routes.visitorDonate()} component={VisitorDonate} />
                <Route path={routes.visitorDonationSuccess()} component={VisitorDonateSuccess} />

                <Route path={routes.teacherPage(":teacherId")} component={TeacherPage} />
                <Route path={routes.publicTeachersPage()} component={TeachersPage} />

                <Route
                    path={routes.intentionsSubmittedPage()}
                    component={IntentionsSubmittedPage}
                />

                <Route component={NotFoundPage} />
            </Switch>

            <ModalRoutes />
        </>
    );
};
