import { Button, Chip, Snackbar, Typography, alpha } from "@mui/material";
import LoadingBar from "components/LoadingBar";
import { CommitmentOptions } from "./constants";
import makeStyles from "@mui/styles/makeStyles";
import { FixedCTAContainer } from "features/signup/FixedCTAContainer";
import { useLocation, useParams } from "react-router";
import { useContext, useState } from "react";
import { isUserInFreeTrialSession } from "features/signup/signupHelpers";
import { MemberContext } from "../MemberContext";
import _ from "lodash";
import { isFreeTrialActiveForMembership } from "../membership/membershipHelpers";
import { CardToggle } from "components/CardToggle";
import { DEFAULT_FREE_TRIAL_DAYS } from "@app/shared/constants";

interface InterestGroupChooseCommitmentOptionInfoProps {
    setCommitmentOption: (option: CommitmentOptions | undefined) => void;
    isSubmitting: boolean;
}

const useStyles = makeStyles((theme) => ({
    contentContainer: {
        paddingBottom: theme.spacing(16),
        [theme.breakpoints.down("sm")]: {
            paddingBottom: theme.spacing(8),
        },
    },
    commitmentIcon: {
        position: "absolute",
        top: 5,
        right: 0,
        transform: "translate(50%, -50%)",
        width: 50,
        height: 50,
        border: `1px solid ${theme.palette.grey200}`,
        borderRadius: "50%",
        [theme.breakpoints.down("lg")]: {
            width: 45,
            height: 45,
            right: theme.spacing(2),
        },
        [theme.breakpoints.down("md")]: {
            width: 40,
            height: 40,
        },
    },
    recommendedBadge: {
        position: "absolute",
        top: 0,
        left: 0,
        transform: "translateY(-50%)",
    },
    noMargin: {
        margin: 0,
    },
    innerCardContainer: {
        marginLeft: theme.spacing(2),
    },
    snackBar: {
        marginTop: theme.spacing(12),
    },
    cardContainer: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(2.5),
    },
}));

export const InterestGroupChooseCommitmentOptionsInfo = (
    props: InterestGroupChooseCommitmentOptionInfoProps,
) => {
    const { setCommitmentOption, isSubmitting } = props;
    const classes = useStyles();

    const memberContext = useContext(MemberContext);
    const activeMemberships = memberContext.memberships;
    const membership = _.first(activeMemberships);

    // Check if user has an active free trial membership
    const hasActiveFreeTrial = membership && isFreeTrialActiveForMembership(membership);

    const [selectedOption, setSelectedOption] = useState<CommitmentOptions | undefined>(
        hasActiveFreeTrial ? CommitmentOptions.DropIn : undefined,
    );

    const [snackBarOpen, setSnackBarOpen] = useState<boolean>(false);
    const showSnackbar = () => {
        setSnackBarOpen(true);
        const initialfadeOut = setTimeout(() => {
            setSnackBarOpen(false);
        }, 7000);

        return () => {
            clearTimeout(initialfadeOut);
        };
    };

    return (
        <div>
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={snackBarOpen}
                onClose={() => {
                    setSnackBarOpen(false);
                }}
                message={`You can be a dedicated group participant after your ${DEFAULT_FREE_TRIAL_DAYS} day free trial.`}
                ContentProps={{
                    className: classes.snackBar,
                }}
            />
            <div className={classes.contentContainer}>
                <Typography variant="h2" sx={{ mb: 2 }}>
                    How would you like to join your group?
                </Typography>

                <div className={classes.cardContainer}>
                    {/* Dedicated card */}
                    <CardToggle
                        isSelected={selectedOption === CommitmentOptions.Commited}
                        onClick={() => {
                            if (hasActiveFreeTrial) {
                                showSnackbar();
                            } else {
                                setSelectedOption(CommitmentOptions.Commited);
                            }
                        }}
                        radioValue={CommitmentOptions.Commited}
                        testId="dedicatedCard"
                        disabled={hasActiveFreeTrial}
                    >
                        <Chip
                            variant="darkGreen"
                            label="Recommended"
                            className={classes.recommendedBadge}
                        />

                        <div className={classes.innerCardContainer}>
                            <Typography variant="h3" className={classes.noMargin}>
                                Dedicated group participant
                            </Typography>
                            <Typography variant="body1" className={classes.noMargin}>
                                Attend weekly group sessions on an ongoing basis. See the same
                                people more often.
                            </Typography>
                        </div>
                    </CardToggle>

                    {/* Drop-in card */}
                    <CardToggle
                        isSelected={selectedOption === CommitmentOptions.DropIn}
                        onClick={() => setSelectedOption(CommitmentOptions.DropIn)}
                        radioValue={CommitmentOptions.DropIn}
                        testId="dropInCard"
                    >
                        <div className={classes.innerCardContainer}>
                            <Typography variant="h3" className={classes.noMargin}>
                                Drop-in group participant
                            </Typography>
                            <Typography variant="body1" className={classes.noMargin}>
                                Attend group sessions flexibly, as it suits you. Meet different
                                people each week.
                            </Typography>
                        </div>
                    </CardToggle>
                </div>
            </div>

            <FixedCTAContainer>
                <Button
                    variant="primary"
                    onClick={() => {
                        window.sessionStorage.setItem(
                            "isDropIn",
                            selectedOption === CommitmentOptions.DropIn ? "true" : "false",
                        );
                        setCommitmentOption(selectedOption);
                    }}
                    data-testid="continueButton"
                    disabled={selectedOption === undefined}
                    fullWidth
                >
                    Continue
                </Button>
            </FixedCTAContainer>
            {isSubmitting && <LoadingBar />}
        </div>
    );
};
