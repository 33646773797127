import { Teacher } from "@app/shared/types";
import { Box, Button, Chip, Tooltip, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { routes } from "app/routes";
import classNames from "classnames";
import { push } from "connected-react-router";
import { useDispatch } from "react-redux";

interface TeachersPageImageCardProps {
    teacher: Teacher;
    isMentor?: boolean;
    showTeacherBadge?: boolean;
}

const TEACHER_IMAGE_HEIGHT = 270;

const useStyles = makeStyles((theme) => ({
    card: {
        position: "relative",
        borderRadius: theme.borderRadius.default,
        boxShadow: theme.shadow.default,
        flexGrow: 1,
        backgroundColor: theme.palette.neutralWarm,
    },
    teacherImage: {
        width: "100%",
        height: TEACHER_IMAGE_HEIGHT,
        borderRadius: theme.borderRadius.default,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        objectFit: "cover",
        objectPosition: "center",
    },
    cardContent: {
        padding: theme.spacing(1, 3, 2, 3),
    },
    infoTextBox: {
        display: "flex",
        alignItems: "center",
    },
    infoIcons: {
        fontSize: theme.typography.pxToRem(18),
        marginRight: theme.spacing(1),
    },
    mentorBadge: {
        backgroundColor: theme.palette.primaryLeaves,
        color: theme.palette.neutralWhite,
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
        position: "absolute",
        marginRight: theme.spacing(0.5),
        right: 0,
        top: TEACHER_IMAGE_HEIGHT - 26, // minus height of the badge itself
        cursor: "default",
    },
    mentorIcon: {
        fontSize: theme.typography.pxToRem(18),
        padding: theme.spacing(0.5),
        color: theme.palette.accentGold,
    },
}));

export const TeachersPageImageCard = (props: TeachersPageImageCardProps) => {
    const { teacher, isMentor, showTeacherBadge } = props;
    const classes = useStyles();

    const dispatch = useDispatch();

    return (
        <Box className={classes.card} data-testid="teachersPageImageCard">
            {showTeacherBadge && (
                <Chip
                    label="Your Teacher"
                    variant="darkGreen"
                    sx={{ position: "absolute", left: "50%", transform: "translate(-50%, -30%)" }}
                />
            )}
            <img
                className={classes.teacherImage}
                src={teacher.picture || undefined}
                alt={teacher.name}
            />
            {isMentor && (
                <Tooltip title="This teacher facilitates a mentorship group.">
                    <Box className={classes.mentorBadge}>
                        <Typography
                            variant="body3"
                            sx={{ display: "flex", alignItems: "center", pr: 1 }}
                        >
                            <span
                                className={classNames(
                                    "material-symbols-rounded",
                                    classes.mentorIcon,
                                )}
                            >
                                award_star
                            </span>
                            <strong>Mentor</strong>
                        </Typography>
                    </Box>
                </Tooltip>
            )}
            <Box className={classes.cardContent}>
                <Typography variant="h3" sx={{ mb: 0.5 }}>
                    {teacher.name}
                </Typography>
                <Typography variant="body1" className={classes.infoTextBox} sx={{ mb: 0 }}>
                    <span className={classNames("material-symbols-rounded", classes.infoIcons)}>
                        location_on
                    </span>
                    {teacher.location}
                </Typography>
                <Typography variant="body1" className={classes.infoTextBox} sx={{ mb: 1 }}>
                    <span className={classNames("material-symbols-rounded", classes.infoIcons)}>
                        play_arrow
                    </span>
                    {teacher.yearsTeaching} years teaching
                </Typography>
                <Button
                    variant="tertiary"
                    onClick={() => dispatch(push(routes.teacherPage(teacher.id)))}
                    data-testid="learnMoreButton"
                >
                    Learn more
                </Button>
            </Box>
        </Box>
    );
};
