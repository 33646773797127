import {
    CUSTOM_PATH_TYPEFORM_URL_DEV,
    CUSTOM_PATH_TYPEFORM_URL_PRODUCTION,
} from "@app/shared/constants";
import { Recommendation } from "@app/shared/types";
import { AdvancedImage, responsive } from "@cloudinary/react";
import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { analyticsTrack } from "app/analytics/track";
import { cloudinary } from "app/cloudinary";
import { isProduction } from "app/config";
import { LoggedInUserProfileContext } from "features/auth/RequireAuthentication";
import { LinkButton } from "features/navigation/LinkButton";
import { DateTime } from "luxon";
import { useContext } from "react";

const CUSTOM_PATH_TYPEFORM_BASE_URL = isProduction()
    ? CUSTOM_PATH_TYPEFORM_URL_PRODUCTION
    : CUSTOM_PATH_TYPEFORM_URL_DEV;

const useStyles = makeStyles((theme) => ({
    card: {
        display: "flex",
        justifyContent: "flex-start",
        minWidth: 350,
        alignItems: "center",
        gap: theme.spacing(2),
        padding: theme.spacing(2),
        borderRadius: theme.borderRadius.default,
        border: `2px solid ${theme.palette.grey400}`,
    },
    cardImage: {
        alignSelf: "flex-start",
        width: 90,
        height: 90,
        minHeight: 90,
        minWidth: 90,
        objectFit: "cover",
        backgroundColor: theme.palette.grey400,
        borderTopLeftRadius: theme.borderRadius.small,
        borderBottomLeftRadius: theme.borderRadius.small,
        borderTopRightRadius: theme.borderRadius.small,
        borderBottomRightRadius: theme.borderRadius.small,
    },
    cardContent: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
    },
    addonBadge: {
        color: theme.palette.primaryLeaves,
        marginBottom: theme.spacing(1),
    },
}));

export const RecommendationCard = (props: { recommendation: Recommendation }) => {
    const { recommendation } = props;
    const user = useContext(LoggedInUserProfileContext);
    let buttonLink = recommendation.buttonLink;
    const classes = useStyles(props);
    const isAddon = recommendation.customPathGoal?.toLowerCase().includes("mentorship");

    const isFinishIntentionSetupRecommendation = buttonLink === "finishIntentionSetup";

    if (isFinishIntentionSetupRecommendation) {
        const encodedUserId = encodeURIComponent(user.id);
        const encodedUserEmail = encodeURIComponent(user.email);
        const formSubmittedDate = Math.floor(DateTime.now().toSeconds());

        buttonLink = `${CUSTOM_PATH_TYPEFORM_BASE_URL}#user_id=${encodedUserId}&email=${encodedUserEmail}&is_reflection=false&intention_form_last_submitted=${formSubmittedDate}`;
    }
    const cloudinaryImage = cloudinary
        .image(recommendation.imageId || "production/custom_path_recommendations/default_bowl")
        .format("auto");

    return (
        <div className={classes.card} key={recommendation.title}>
            <AdvancedImage
                cldImg={cloudinaryImage}
                className={classes.cardImage}
                plugins={[responsive({ steps: 200 })]}
                alt={recommendation.title}
            />
            <div className={classes.cardContent}>
                <div>
                    {isAddon && (
                        <Typography
                            variant="body3"
                            textTransform="uppercase"
                            className={classes.addonBadge}
                        >
                            <strong>Add-on</strong>
                        </Typography>
                    )}
                    <Typography variant="h6">{recommendation.title}</Typography>
                    <Typography variant="caption" paragraph sx={{ lineHeight: "normal" }}>
                        {recommendation.description}
                    </Typography>
                </div>

                <div>
                    <LinkButton
                        to={buttonLink}
                        variant="secondary"
                        size="small"
                        fullWidth={false}
                        onClick={() => {
                            analyticsTrack("Recommendation Button Clicked", {
                                recommendationCustomPathGoal: recommendation.customPathGoal,
                                recommendationData: recommendation,
                            });
                        }}
                    >
                        {recommendation.buttonText}
                    </LinkButton>
                </div>
            </div>
        </div>
    );
};

export default RecommendationCard;
