import { useQuery } from "@apollo/client";
import { MembershipStatus } from "@app/shared/constants";
import { Membership } from "@app/shared/types";
import { analyticsTrack } from "app/analytics/track";
import { GRAPHQL_QUERY_MEMBERSHIPS } from "app/queries";
import { routes } from "app/routes";
import LoadingPage from "features/pages/LoadingPage";
import { useLogEventOnLoad } from "hooks/useLogEventOnLoad";
import { useOptimizelyTrackOnLoad } from "hooks/useOptimizelyTrack";
import { useQueryParams } from "hooks/useQueryParams";
import _ from "lodash";
import { useEffect, useState } from "react";
import { Redirect } from "react-router";
import { getSignupAnalyticsMetadataFromPlanName } from "./signupHelpers";

export const SignupSuccess = () => {
    const query = useQueryParams();

    const { sessionId, planName, customerId, value } = query;

    const analyticsData = getSignupAnalyticsMetadataFromPlanName(planName as string);
    useLogEventOnLoad("signup.success.load", { ...analyticsData, sessionId });
    useOptimizelyTrackOnLoad("explorerSignupComplete");

    // Our server should pick up an event from stripe, and process it to create a membership object
    // Poll the users memberships and redirect when an active/pending one is found
    const { data: membershipData } = useQuery(GRAPHQL_QUERY_MEMBERSHIPS, {
        pollInterval: 5000,
        fetchPolicy: "network-only",
    });

    const memberships = (membershipData?.myMemberships || []) as Membership[];
    const activeOrPending = memberships.filter((x) =>
        _.includes(MembershipStatus.ActiveOrPending, x.status),
    );

    const [successLogged, setSuccessLogged] = useState(false);

    useEffect(() => {
        if (activeOrPending.length > 0) {
            analyticsTrack("signup.success.complete", {
                ...analyticsData,
                currency: "USD",
                // Note: we add value and $revenue for FB ads - since our back-end event doesn't work for the conversion event there
                // We don't add revenue that hooks up to attribution, to avoid double counting signup revenue
                // TODO: discounts entered during checkout won't be reflected the revenue here
                // This field comes from: https://github.com/cloudsangha/cloudsangha/blob/master/functions/src/memberships/payForMembershipSignup.ts#L226
                value,
                $revenue: value,
                sessionId,
            });
            setSuccessLogged(true);
        }
    }, [successLogged, activeOrPending.length, analyticsData, sessionId]);

    useEffect(() => {
        const rfsn = {
            cart: customerId,
            id: localStorage.getItem("rfsn_v4_id"),
            url: window.location.href,
            aid: localStorage.getItem("rfsn_v4_aid"),
            cs: localStorage.getItem("rfsn_v4_cs"),
        };
        (window as any).r?.sendCheckoutEvent(rfsn.cart, rfsn.id, rfsn.url, rfsn.aid, rfsn.cs);
    }, []);

    if (activeOrPending.length > 0) {
        return <Redirect to={routes.setupFlow()} />;
    } else {
        return <LoadingPage caption="Processing" />;
    }
};
