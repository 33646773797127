import { Session, SessionType } from "@app/shared/types";
import { dateTimeFromString } from "@app/shared/utils";
import { MenuItem, Typography, alpha } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { analyticsTrack } from "app/analytics/track";
import { getFullUrl, routes } from "app/routes";
import classNames from "classnames";
import { useUserTimezone } from "hooks/useUserTimezone";
import { DateTime } from "luxon";
import { useEffect } from "react";
import smoothscroll from "smoothscroll-polyfill";
import { getSessionOrSanghaTitle } from "./shared";
import EventAvailableOutlinedIcon from "@mui/icons-material/EventAvailableOutlined";
import { BRAND_NAME } from "@app/shared/constants";

smoothscroll.polyfill();

const useStyles = makeStyles((theme) => ({
    menuItem: {
        marginBottom: 0,
    },
    menuItemIcon: {
        marginRight: theme.spacing(2),
    },
}));

export const getEventAnalyticsData = (event: Session) => ({
    eventId: event.id,
    sanghaId: event.sangha?.id,
    sanghaName: event.sangha?.name,
    eventType: event.type,
    teacherId: event.teachers.length ? event.teachers[0].id : "",
    eventTime: event.time,
    hourOfDay: DateTime.fromISO(event.time, {
        zone: "America/Los_Angeles",
    }).hour,
    dayOfWeek: DateTime.fromISO(event.time, {
        zone: "America/Los_Angeles",
    }).weekday,
    eventTitle: event.title,
});

export const renderAddToCalendarMetadata = (
    event: Session,
    timezone: string,
    isRecurringEvent?: boolean,
) => {
    const getCalendarTime = (eventTime: string) => {
        // AddEvent requires specific date formatting to work correctly
        return dateTimeFromString(eventTime, timezone).toFormat("MM/dd/yyyy HH:mm").toLowerCase();
    };

    const getEventUrl = () => {
        if (event.sangha?.id) {
            return getFullUrl(routes.memberSanghaDetails(event.sangha?.id));
        }
        return getFullUrl(`/member/home?communityEventId=${event.id}&sessionType=${event.type}`);
    };

    const getCalendarEventDescription = () => {
        switch (event.type) {
            case SessionType.SANGHA_SESSION:
                return (
                    <Typography variant="body1" component="div">
                        Your dedicated mentorship group where you'll meet weekly with the same group
                        of people and the same expert teacher. What might you uncover this time?
                    </Typography>
                );
            case SessionType.COMMUNITY_SIT:
                return (
                    <Typography variant="body1" component="div">
                        Sit back, relax and let an expert meditation teacher guide you in a
                        30-minute session. These sessions with cover a variety of topics and
                        techniques are are the perfect anchor for building consistency in your
                        practice.
                    </Typography>
                );
            case SessionType.COMMUNITY_TALK:
                return (
                    <Typography variant="body1" component="div">
                        Q&amp;A Sessions are led by world-renowned meditation teachers and offer a
                        regular opportunity to submit your own practice questions and hear live
                        responses in real-time. No questions? No problem. Tune in the hear what's on
                        others' hearts and chances are you'll gain some insight into your own
                        experience too. <br />
                        <br /> Here are just a few of the questions that have been addressed in
                        recent sessions... <br />
                        <br />
                        <ul>
                            <li>
                                How do I keep an open heart when the outside world seems so
                                threatening and confusing?
                            </li>
                            <li>
                                Why are strong emotions so persistent? How do you work with them?
                            </li>
                            <li>
                                We're often told to focus on body sensations during meditation, but
                                I'm dealing with a lot of pain. How do I navigate this?
                            </li>
                            <li>
                                How do I balance protecting myself from difficult people while still
                                having compassions for them?
                            </li>
                        </ul>
                    </Typography>
                );
            default:
                return "";
        }
    };

    return (
        <>
            <span className="start">{getCalendarTime(event.time)}</span>
            <span className="end">{getCalendarTime(event.endTime)}</span>
            <span className="timezone">{timezone}</span>
            <span className="title">{`${getSessionOrSanghaTitle(event)} | ${BRAND_NAME}`}</span>
            <span className="description">
                {getCalendarEventDescription()}
                <div>
                    Join now: <br />
                    {getEventUrl()}
                </div>
            </span>
            <span className="location">{getEventUrl()}</span>
            <span className="alarm_reminder">15</span>
            {isRecurringEvent && <span className="recurring">FREQ=WEEKLY;</span>}
        </>
    );
};

export const AddToCalendarMenuItem = (props: { event: Session; isRecurringEvent?: boolean }) => {
    const classes = useStyles();
    const timezone = useUserTimezone();

    useEffect(() => {
        (window as any).addeventatc?.refresh();
    }, []);

    return (
        <div
            title="Add to Calendar"
            className={classNames("addeventatc")}
            data-styling="none"
            data-testid="addToCalendarButton"
            onClick={() => {
                analyticsTrack("addToCalendarButton.click", getEventAnalyticsData(props.event));
            }}
        >
            <MenuItem className={classes.menuItem}>
                <EventAvailableOutlinedIcon className={classes.menuItemIcon} />
                Add to my calendar
            </MenuItem>
            {renderAddToCalendarMetadata(props.event, timezone, props.isRecurringEvent)}
        </div>
    );
};
