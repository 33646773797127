import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { IntentionInput, Recommendation } from "@app/shared/types";
import { dateTimeFromString } from "@app/shared/utils";
import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { analyticsTrack } from "app/analytics/track";
import {
    GRAPHQL_MUTATION_SAVE_INTENTION,
    GRAPHQL_QUERY_LATEST_INTENTION,
    GRAPHQL_QUERY_MY_RECOMMENDATIONS,
} from "app/queries";
import { theme } from "app/theme";
import { RefreshIntentionButton } from "components/RefreshIntentionButton";
import { SetIntentionButton } from "components/SetIntentionButton";
import IntentionSummary from "components/IntentionSummary";
import { GenericErrorPage } from "components/GenericErrorPage";
import PageWrapper from "components/PageWrapper";
import { RecommendationCard } from "components/RecommendationCard";
import { LoggedInUserProfileContext } from "features/auth/RequireAuthentication";
import { useQueryParams } from "hooks/useQueryParams";
import { useUserTimezone } from "hooks/useUserTimezone";
import { DateTime } from "luxon";
import { useContext, useEffect, useState } from "react";
import LoadingPage from "./LoadingPage";

const useStyles = makeStyles((theme) => ({
    header: {
        [theme.breakpoints.up("md")]: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
        },
    },
    headerTitle: {
        marginBottom: 0,
    },
    createdOn: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2),
        fontWeight: theme.typography.fontWeightBold,
    },
    intentionCard: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        gap: theme.spacing(4),
        padding: theme.spacing(3),
        borderRadius: theme.borderRadius.default,
        backgroundColor: theme.palette.neutralGold,
        color: theme.palette.grey800,
    },
    refreshIntentionCard: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        marginTop: theme.spacing(2),
        padding: theme.spacing(3),
        gap: theme.spacing(1),
        borderRadius: theme.borderRadius.default,
        backgroundColor: theme.palette.primaryLeaves,
        color: theme.palette.neutralWhite,
    },
    recommendationCardsContainer: {
        display: "grid",
        gridTemplateColumns: "repeat(auto-fill, minmax(350px, 1fr))",
        gap: theme.spacing(2),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(4),
    },
}));

function cleanTypeformEmptyValue(value: string): string {
    return value === "_____" ? "" : value;
}

function getFirstQueryParam(queryParamValue: string | string[] | null | undefined): string {
    if (Array.isArray(queryParamValue)) {
        return cleanTypeformEmptyValue(queryParamValue[0]) ?? "";
    } else if (queryParamValue === null || queryParamValue === undefined) {
        return "";
    } else {
        return cleanTypeformEmptyValue(queryParamValue);
    }
}

function parseCustomPathGoals(customPathGoals: string): string[] {
    const customPathGoalsArray = customPathGoals.split(".,").filter((option) => option);
    if (customPathGoalsArray[0] !== "") {
        return customPathGoalsArray.map((option) => "- " + option.trim());
    }
    return customPathGoalsArray;
}

/**
 * This function decodes a numeric sum representing the selected options from a Typeform multiple-choice question into an array of recommendation goal identifiers (Typeform question link: https://admin.typeform.com/form/qQ3Rj9yk/create?block=55022cbd-0b2f-4e69-800b-5a4cd59d857c).
 *
 * In the Intention and Reflection Typeform (specifically question four, where the member can select up to 3 options), each option is assigned a unique value as a power of two (2^i), where 'i' is the index of the option. This encoding ensures that every combination of selected options results in a unique sum.
 *
 * The function takes the total sum of the selected option values and determines which options were selected by iteratively subtracting the option values from the sum, starting from the highest value.
 *
 * Example: The member selects "qaGoal" (value 256) and "exploreTeachersGoal" (value 64). The total sum is 320.
 * Decoding steps:
 * - 256 (option value) is <= 320 (sum): subtract 256 from the sum -> remaining sum is 64; add "qaGoal" to the list.
 * - 128 (option value) isn't <= 64 (sum): move to next lower value.
 * - 64 (option value) is <= 64 (sum): subtract 64 from the sum -> remaining sum is 0; add "exploreTeachersGoal" to the list and break out of the loop.
 * decodeRecommendationGoals(320) returns ["qaGoal", "exploreTeachersGoal"]
 *
 * @param {number} typeformOptionsSum - The total sum of the selected option values from Typeform.
 * @returns {string[]} An array of recommendation goal identifiers corresponding to the selected options.
 */
function decodeRecommendationGoals(typeformOptionsSum: number): string[] {
    const decodedRecommendations = [];

    const recommendationIdentifiers = [
        "mfgGoal", // Typeform option value 1
        "affinityGroupGoal", // Typeform option value 2
        "mentorshipGroupGoal", // Typeform option value 4
        "talksGoal", // Typeform option value 8
        "mediationsGoal", // Typeform option value 16
        "loungeGoal", // Typeform option value 32
        "exploreTeachersGoal", // Typeform option value 64
        "mrcGoal", // Typeform option value 128
        "qaGoal", // Typeform option value 256
    ];

    // Loop through the recommendation labels in reverse order (from highest option value to lowest)
    for (let i = recommendationIdentifiers.length - 1; i >= 0; i--) {
        const typeformOptionValue = Math.pow(2, i); // On typeform each option has a value of 2^i

        // If the current option value can be subtracted from the sum add the option label to the selected options
        if (typeformOptionsSum >= typeformOptionValue) {
            typeformOptionsSum -= typeformOptionValue;
            decodedRecommendations.push(recommendationIdentifiers[i]);
        }

        // If the sum is reduced to zero, break out of the loop
        if (typeformOptionsSum === 0) {
            break;
        }
    }

    return decodedRecommendations;
}

export const IntentionDetailPage = () => {
    const classes = useStyles();
    const timeZone = useUserTimezone();

    const loggedInUserInfo = useContext(LoggedInUserProfileContext);

    const [intention, setIntention] = useState<IntentionInput | null>(null);
    const [isIntentionOutdated, setIsIntentionOutdated] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const {
        user_id: userIdQueryParams,
        intention: fullIntentionQueryParams,
        reflection: fullReflectionQueryParams,
        custom_path_goals: customPathGoalsQueryParams,
        recommendation_goals: recommendationGoalsEncodedQueryParams,
        mfg_recommendation_variable: mfgGoalGroupQueryParams,
        mentorship_recommendation_variable: mentorshipGoalGroupQueryParams,
        affinity_recommendation_variable: affinityGoalGroupQueryParams,
    } = useQueryParams();

    const userIdParam = getFirstQueryParam(userIdQueryParams);
    const fullIntentionParam = getFirstQueryParam(fullIntentionQueryParams);
    const fullReflectionParam = getFirstQueryParam(fullReflectionQueryParams);
    const mfgGroupRecommendationParam = getFirstQueryParam(mfgGoalGroupQueryParams);
    const mentorshipGroupRecommendationParam = getFirstQueryParam(mentorshipGoalGroupQueryParams);
    const affinityGroupRecommendationParam = getFirstQueryParam(affinityGoalGroupQueryParams);

    const customPathGoalsParam = parseCustomPathGoals(
        getFirstQueryParam(customPathGoalsQueryParams),
    );

    const recommendationGoalsDecodedParam = decodeRecommendationGoals(
        Number(recommendationGoalsEncodedQueryParams),
    );

    const typeformRecommendationsArray = [...recommendationGoalsDecodedParam];

    if (mfgGroupRecommendationParam) {
        typeformRecommendationsArray.push(mfgGroupRecommendationParam);
    }

    if (mentorshipGroupRecommendationParam) {
        typeformRecommendationsArray.push(mentorshipGroupRecommendationParam);
    }

    if (affinityGroupRecommendationParam) {
        typeformRecommendationsArray.push(affinityGroupRecommendationParam);
    }

    const [saveIntentionMutation] = useMutation(GRAPHQL_MUTATION_SAVE_INTENTION);

    const {
        data: intentionData,
        loading: intentionLoading,
        error: intentionError,
    } = useQuery(GRAPHQL_QUERY_LATEST_INTENTION, {
        fetchPolicy: "network-only",
        skip: !!userIdParam, // Skip fetching intention if it was set via query params
    });

    const [fetchRecommendations, { data: recommendationsData }] = useLazyQuery(
        GRAPHQL_QUERY_MY_RECOMMENDATIONS,
        {
            fetchPolicy: "network-only",
        },
    );

    useEffect(() => {
        const handleIntentionDataAndRecommendations = async () => {
            try {
                let currentIntention = null;

                if (userIdParam) {
                    // Scenario 1: Query params exist, save Typeform answers on our DB
                    const intentionFromParams: IntentionInput = {
                        userId: userIdParam,
                        fullIntention: fullIntentionParam,
                        fullReflection: fullReflectionParam,
                        recommendations: typeformRecommendationsArray,
                        customPathGoals: customPathGoalsParam,
                    };

                    const { data } = await saveIntentionMutation({
                        variables: { intention: intentionFromParams },
                    });
                    currentIntention = data.saveIntention;
                    analyticsTrack("Intention Submitted", {
                        intentionSubmittedData: intentionFromParams,
                        intentionSavedData: currentIntention,
                    });
                    // Remove query params after saving intention to avoid re-saving on page refresh
                    window.history.replaceState({}, document.title, window.location.pathname);
                } else {
                    // Scenario 2: No query params, use fetched intention
                    currentIntention = intentionData.latestIntention;

                    analyticsTrack("Intention Detail Viewed", {
                        intentionData: currentIntention,
                    });
                }

                if (currentIntention) {
                    setIntention(currentIntention);

                    // Check if the intention is outdated
                    if (currentIntention.createdDate) {
                        const createdDate = dateTimeFromString(
                            currentIntention.createdDate,
                            timeZone,
                        );
                        const now = DateTime.now();
                        const diffInMonths = now.diff(createdDate, "months").months;
                        setIsIntentionOutdated(diffInMonths >= 1);
                    }

                    // Fetch recommendations
                    if (currentIntention?.recommendations?.length > 0) {
                        await fetchRecommendations({
                            variables: {
                                customPathGoals: currentIntention.recommendations,
                            },
                        });
                    } else {
                        analyticsTrack("Recommendations not fetched due to invalid or missing parameters", {
                            currentIntention,
                        });
                    }
                }
            } catch (err) {
                setError(true);
            } finally {
                setLoading(false);
            }
        };

        if (userIdParam || (!intentionLoading && intentionData)) {
            handleIntentionDataAndRecommendations();
        }
    }, [userIdParam, intentionLoading, intentionData]);

    if (loading || intentionLoading) {
        return <LoadingPage />;
    }

    if (error || intentionError) {
        return <GenericErrorPage />;
    }

    if (!intention) {
        return (
            <PageWrapper smallPaddingTop>
                <Typography variant="h4">No intention found</Typography>
                <SetIntentionButton
                    userId={loggedInUserInfo.id}
                    userEmail={loggedInUserInfo.email}
                />
            </PageWrapper>
        );
    }

    const recommendations = recommendationsData?.myRecommendations;

    const handleUpdateIntentionSummary = (newSummary: string) => {
        if (intention) {
            setIntention({ ...intention, intentionSummary: newSummary });
        }
    };

    return (
        <PageWrapper smallPaddingTop>
            <div className={classes.header}>
                <Typography variant="h2" className={classes.headerTitle}>
                    My intention for the month
                </Typography>
                {!isIntentionOutdated && (
                    <RefreshIntentionButton
                        userId={loggedInUserInfo.id}
                        userEmail={loggedInUserInfo.email}
                        customPathGoals={intention.customPathGoals}
                        fullIntention={intention.fullIntention}
                    />
                )}
            </div>

            {isIntentionOutdated && (
                <div className={classes.refreshIntentionCard}>
                    <div>
                        <Typography variant="h4">
                            It's time to reflect and refresh your intentions.
                        </Typography>
                        <Typography variant="body1">
                            It's been a month since you set your intentions. It's time to reflect
                            and refresh them. It's possible to keep the same intention too.
                        </Typography>
                    </div>
                    <RefreshIntentionButton
                        userId={loggedInUserInfo.id}
                        userEmail={loggedInUserInfo.email}
                        customPathGoals={intention.customPathGoals}
                        fullIntention={intention.fullIntention}
                    />
                </div>
            )}

            <Typography variant="caption" className={classes.createdOn}>
                {userIdParam
                    ? "Created now"
                    : intention.createdDate
                      ? `Created on ${DateTime.fromISO(String(intention.createdDate)).toFormat("MMMM dd, yyyy")}`
                      : "Date not available"}
            </Typography>

            <div className={classes.intentionCard}>
                <div>
                    <Typography variant="h5" sx={{ color: theme.palette.accentEarthy }}>
                        What is your deepest intention for your practice?
                    </Typography>
                    <Typography variant="subtitle1">"{intention?.fullIntention}"</Typography>
                </div>
                <IntentionSummary
                    currentIntentionSummary={
                        intention?.intentionSummary || "Summary not found, please add one"
                    }
                    onUpdateIntentionSummary={handleUpdateIntentionSummary}
                />
            </div>

            {recommendations && recommendations.length > 0 && (
                <div>
                    <Typography
                        variant="h5"
                        sx={{
                            marginTop: theme.spacing(4),
                        }}
                        textTransform="uppercase"
                    >
                        What will most support your intention
                    </Typography>
                    <Typography variant="subtitle1" sx={{ color: theme.palette.grey700 }}>
                        According to your answer on your intentions setting. If you would like to
                        change them, please create a new intention.
                    </Typography>

                    <div className={classes.recommendationCardsContainer}>
                        {recommendations?.map((recommendation: Recommendation) => {
                            return (
                                <RecommendationCard
                                    key={recommendation.title}
                                    recommendation={recommendation}
                                />
                            );
                        })}
                    </div>
                </div>
            )}
        </PageWrapper>
    );
};
