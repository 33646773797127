import { analyticsTrack } from "app/analytics/track";
import { GenericErrorPage } from "components/GenericErrorPage";
import { selectUserProfile } from "features/auth/auth";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SetupGuidelinesPage } from "./SetupGuidelinesPage";
import { SetupWelcomePage } from "./SetupWelcomePage";
import { useHistory } from "react-router";
import { routes } from "app/routes";
import { EditProfilePage } from "features/member/profile/EditProfilePage";
import { ProgressBar } from "features/signup/ProgressBar";

export enum SetupSteps {
    Welcome = "welcome",
    Profile = "profile",
    Guidelines = "guidelines",
}

const setupStepData = {
    [SetupSteps.Welcome]: {
        step: 0,
        label: "Welcome",
    },
    [SetupSteps.Profile]: {
        step: 1,
        label: "Set your profile",
    },
    [SetupSteps.Guidelines]: {
        step: 2,
        label: "Our guidelines",
    },
};

const setupStepOrder = [SetupSteps.Welcome, SetupSteps.Profile, SetupSteps.Guidelines];

export const SetupFlow = () => {
    const userProfile = useSelector(selectUserProfile);
    const history = useHistory();

    const [currentStep, setCurrentStep] = useState<SetupSteps>(SetupSteps.Welcome);

    useEffect(() => {
        if (!userProfile) {
            analyticsTrack("onboarding.missing-user-profile");
        }
    }, [userProfile]);

    if (!userProfile) {
        return <GenericErrorPage />;
    }

    const renderStepComponent = () => {
        if (currentStep === SetupSteps.Welcome) {
            return <SetupWelcomePage onContinue={() => setCurrentStep(SetupSteps.Profile)} />;
        } else if (currentStep === SetupSteps.Profile) {
            return <EditProfilePage onContinue={() => setCurrentStep(SetupSteps.Guidelines)} />;
        } else {
            return <SetupGuidelinesPage onContinue={() => history.push(routes.memberHomePage())} />;
        }
    };

    return (
        <>
            <ProgressBar
                currentStep={setupStepData[currentStep].step}
                stepData={setupStepData}
                stepOrder={setupStepOrder}
                onStepClick={(step: string) => setCurrentStep(step as SetupSteps)}
            />
            {renderStepComponent()}
        </>
    );
};
