import { Typography } from "@mui/material";
import PageWrapper from "components/PageWrapper";
import { Widget } from "@typeform/embed-react";
import { useMutation } from "@apollo/client";
import { GRAPHQL_MUTATION_COMPLETE_INTAKE_SURVEY } from "app/queries";
import { GenericErrorPage } from "components/GenericErrorPage";
import { useCallback } from "react";

interface IntakeSurveyPageProps {
    sanghaId: string;
    userId: string;
    email: string;
    sanghaMembershipId: string;
    teacherEmail: string;
}

export const IntakeSurveyPage = (props: IntakeSurveyPageProps) => {
    const { sanghaId, userId, email, sanghaMembershipId, teacherEmail } = props;

    const [completeIntakeSurveyMutation, { error }] = useMutation(
        GRAPHQL_MUTATION_COMPLETE_INTAKE_SURVEY,
    );

    const handleSubmit = useCallback(async () => {
        const result = await completeIntakeSurveyMutation({
            variables: {
                sanghaMembershipId,
            },
        });
        if (result.data.completeIntakeSurvey === true) {
            window.location.reload();
        }
    }, []);

    if (error) {
        return (
            <GenericErrorPage alternateInstruction="Something went wrong while submitting the survey. Please" />
        );
    }

    return (
        <PageWrapper>
            <Typography variant="h2" sx={{ mb: 0 }}>
                Welcome to your mentorship group!
            </Typography>
            <Typography variant="body1">
                Please share your intentions with your teacher to ensure they understand your needs
                and what you're looking to experience.
            </Typography>
            <Widget
                id="oLidYEk6"
                height="65vh"
                hidden={{
                    sangha_id: sanghaId,
                    user_id: userId,
                    email: email,
                    teacher_email: teacherEmail || "",
                }}
                onSubmit={handleSubmit}
            />
        </PageWrapper>
    );
};
